import React from "react";
import styled from "styled-components";

const Container = styled.button`
  background: transparent;
  border: none;
  display: inline;
  color: rgba(0, 0, 0, 0.68);
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #5f5fcf;
  }
`;

export default function LinkButton({ children, ...props }) {
  return (
    <Container type="button" {...props}>
      {children}
    </Container>
  );
}
