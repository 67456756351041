import styled from "styled-components";
import PrimaryButton from "../PrimaryButton";

const StyledButton = styled(PrimaryButton)`
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  color: #444;

  &:hover {
    background: #e74c3c;
    color: white;
  }
`;

export default function DeleteButton({ children = "Sil", ...props }) {
  return <StyledButton {...props}>{children}</StyledButton>;
}
