import styled from "styled-components";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import { useEffect, useState } from "react";
import API from "../../../axe-api/api";
import patch from "../../../axe-api/patch";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import StudentLink from "../../../components/StudentLink";
import Loader from "../../../components/Loader";
import TextInput from "../../../components/Form/TextInput";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SmallTextInput = styled(TextInput)`
  padding: 10px;
  text-align: right;
`;

export default function Placement() {
  const [students, setStudents] = useState(null);

  const fetchStudents = async () => {
    const data = await API("students")
      .where("is_active", "=", true)
      .with("school{name}")
      .fetch({ page: 1, per_page: 200 });
    setStudents(data);
  };

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (student, { value }) => {
    const newState = {
      ...students,
      data: students.data.map((item) => {
        if (item.id === student.id) {
          return {
            ...item,
            order_index: value || 0,
          };
        }
        return item;
      }),
    };
    setStudents(newState);
    patch(`students/${student.id}`, { order_index: value || 0 });
  };

  if (!students) return <Loader />;

  const orderedList = students.data.sort(
    (a, b) => a.order_index - b.order_index
  );

  return (
    <>
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Sıralama</PageTitle>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title width="1%">#</Title>
            <Title>Ad Soyad</Title>
            <Title>Okul</Title>
            <Title width="20%">Başarı Sırası</Title>
          </Head>
          <Body>
            {orderedList.map((student, index) => (
              <Row key={student.id}>
                <Cell>{index + 1})</Cell>
                <Cell>
                  <StudentLink student={student}>{student.name}</StudentLink>
                </Cell>
                <Cell>{student?.school?.name}</Cell>
                <Cell>
                  <SmallTextInput
                    type="number"
                    value={student.order_index || 0}
                    containerStyle={{ marginBottom: "0px" }}
                    onChange={(change) => handleChange(student, change)}
                  />
                </Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
      </DashboardTemplate>
    </>
  );
}
