import { useCallback } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { debounce } from "lodash";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import Drawer from "../../../components/Drawer";
import { useEffect, useState } from "react";
import NewExamForm from "./NewExamForm";
import API from "../../../axe-api/api";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { useQueryFilters } from "../../../hooks";
import SearchInput from "../../../components/Form/SearchInput";
import { EXAM_TYPE_LABELS } from "../../../constants";
import InnerLink from "../../../components/InnerLink";
import Loader from "../../../components/Loader";
import Number from "../../../components/Number";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function GroupExamsListPage() {
  const [drawer, setDrawer] = useState(null);
  const [exams, setExams] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });

  const handleAddButton = () => {
    setDrawer({
      title: "Toplu Sınav Ekle",
      component: <NewExamForm onClose={() => setDrawer(null)} />,
    });
  };

  const fetchExams = async () => {
    const query = API("group-exams");

    if (filters.searchText) {
      query.whereLike("exam_type", filters.searchText);
    }

    const data = await query.sort("-created_at").fetch(filters);
    setExams(data);
  };

  useEffect(() => {
    fetchExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  if (!exams) return <Loader />;

  return (
    <>
      <Drawer data={drawer} onClose={setDrawer} />
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Toplu Sınav</PageTitle>
          <SearchInput onChange={debouncedSearchChange} />
          <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title style={{ width: "1%" }}></Title>
            <Title style={{ width: "1%" }}>No</Title>
            <Title>Sınav Türü</Title>
            <Title>Tarih</Title>
          </Head>
          <Body>
            {exams.data.map((exam, index) => (
              <Row key={index}>
                <Cell style={{ fontWeight: "bold" }}>{index + 1})</Cell>
                <Cell>
                  <Number>{exam.id}</Number>
                </Cell>
                <Cell>
                  <InnerLink to={`/group-exams/${exam.id}`}>
                    {EXAM_TYPE_LABELS[exam.exam_type]}
                  </InnerLink>
                </Cell>
                <Cell>{dayjs(exam.created_at).format("DD-MM-YYYY")}</Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
        <Pagination
          data={exams.pagination}
          filters={filters}
          setFilters={setFilters}
        />
      </DashboardTemplate>
    </>
  );
}
