import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #5f5fcf;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const FormBody = styled.div`
  background-color: white;
  width: 500px;
  min-width: 400px;
  border-radius: 4px;
  padding: 20px 40px 40px 40px;
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
`;

export default function AuthTemplate({ children }) {
  return (
    <Container>
      <FormContainer>
        <FormBody>{children}</FormBody>
      </FormContainer>
    </Container>
  );
}
