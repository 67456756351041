import React from "react";
import styled from "styled-components";

const Badge = styled.span`
  padding: 4px 8px;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500px;
`;

const Active = styled(Badge)`
  background: #1abc9c;
`;

const Passive = styled(Badge)`
  background: #e74c3c;
`;

export default function IsActive({ value }) {
  if (value) {
    return <Active>Aktif</Active>;
  }
  return <Passive>Pasif</Passive>;
}
