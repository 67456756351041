import styled from "styled-components";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import { useEffect, useState } from "react";
import API from "../../../axe-api/api";
import deleteRequest from "../../../axe-api/deleteRequest";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import { EXAM_TYPE_LABELS } from "../../../constants";
import TextInput from "../../../components/Form/TextInput";
import patch from "../../../axe-api/patch";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import DeleteButton from "../../../components/Form/DeleteButton";
import Loader from "../../../components/Loader";
import { getExamShareLink } from "../../../helpers/shared";
import { addQuestion } from "../../../store/actions/question";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NumberInput = styled(TextInput)`
  text-align: right;
  width: 100px;
  padding: 6px 4px;
`;

const INPUT_CONTAINER = {
  marginBottom: "0px",
};

const Warning = ({ examLesson: data }) => {
  const hasError =
    data.correct_answers + data.incorrect_answers > data.question_count;
  return (
    <>
      {hasError ? (
        <div style={{ textAlign: "right", color: "#d63031" }}>HATA</div>
      ) : (
        <div style={{ textAlign: "right", color: "#00b894" }}>OK</div>
      )}
    </>
  );
};

const getTotalInArray = (array, key) => {
  return array.reduce((previousValue, currentValue) => {
    return currentValue[key] + previousValue;
  }, 0);
};

const DateBox = styled.span`
  font-size: 18px;
  color: #999;
`;

export default function ExamsDetailPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: examId } = useParams();
  const [exam, setExam] = useState(null);

  const fetchExam = async () => {
    const data = await API(`exams/${examId}`)
      .with("lessons{lesson{name}},student{name},subject{name}")
      .fetch({ page: 1, per_page: 100 });
    setExam(data);
  };

  useEffect(() => {
    fetchExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async ({ name, value, id }) => {
    if (!value) {
      return;
    }

    const data = await patch(`exams/${examId}/lessons/${id}`, {
      [name]: value,
    });

    const newState = {
      ...exam,
      lessons: exam.lessons.map((examLesson) => {
        if (examLesson.id === id) {
          return {
            ...examLesson,
            [name]: parseInt(value),
            net_value: data.net_value,
          };
        }
        return examLesson;
      }),
    };
    setExam(newState);
  };

  const copyLink = (exam) => {
    const link = getExamShareLink(exam);
    copy(link);
    toast("Link panoya kopyalandı");
  };

  const deleteExam = async (exam) => {
    await deleteRequest(`exams/${exam.id}`);
    history.goBack();
  };

  const deleteExamQuestion = (exam) => {
    dispatch(
      addQuestion(
        "Emin misin?",
        "Bu kaydı silmek istediğinden emin misin? Bu işlem geri alınmaz.",
        () => deleteExam(exam)
      )
    );
  };

  if (!exam) return <Loader />;

  return (
    <>
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>
            {exam?.student?.name}: {EXAM_TYPE_LABELS[exam.exam_type]}
            {exam.subject && ` (${exam.subject.name})`}
            <DateBox>{dayjs(exam.created_at).format("DD-MM-YYYY")}</DateBox>
            <div>
              <PrimaryButton onClick={() => copyLink(exam)}>
                Linki Kopyala
              </PrimaryButton>
              <DeleteButton
                style={{ marginLeft: "5px" }}
                onClick={() => deleteExamQuestion(exam)}
              >
                Sil
              </DeleteButton>
            </div>
          </PageTitle>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title style={{ width: "1%" }}>#</Title>
            <Title>Ders</Title>
            <Title style={{ textAlign: "right" }}>Soru Sayısı</Title>
            <Title style={{ textAlign: "right" }}>Doğru Cevap</Title>
            <Title style={{ textAlign: "right" }}>Yanlış Cevap</Title>
            <Title style={{ textAlign: "right" }}>Boş Sayısı</Title>
            <Title style={{ textAlign: "right" }}>NET</Title>
            <Title style={{ width: "50px" }}></Title>
          </Head>
          <Body>
            {exam.lessons.map((examLesson, index) => (
              <Row key={index}>
                <Cell style={{ fontWeight: "bold" }}>{index + 1})</Cell>
                <Cell>{examLesson?.lesson?.name}</Cell>
                <Cell style={{ textAlign: "right" }}>
                  <NumberInput
                    name="question_count"
                    containerStyle={INPUT_CONTAINER}
                    type="number"
                    defaultValue={examLesson.question_count}
                    onChange={(params) =>
                      handleChange({ ...params, id: examLesson.id })
                    }
                  />
                </Cell>
                <Cell style={{ textAlign: "right" }}>
                  <NumberInput
                    name="correct_answers"
                    containerStyle={INPUT_CONTAINER}
                    type="number"
                    defaultValue={examLesson.correct_answers}
                    onChange={(params) =>
                      handleChange({ ...params, id: examLesson.id })
                    }
                  />
                </Cell>
                <Cell style={{ textAlign: "right" }}>
                  <NumberInput
                    name="incorrect_answers"
                    containerStyle={INPUT_CONTAINER}
                    type="number"
                    defaultValue={examLesson.incorrect_answers}
                    onChange={(params) =>
                      handleChange({ ...params, id: examLesson.id })
                    }
                  />
                </Cell>
                <Cell style={{ textAlign: "right" }}>
                  <NumberInput
                    containerStyle={INPUT_CONTAINER}
                    disabled
                    type="number"
                    value={
                      examLesson.question_count -
                      examLesson.correct_answers -
                      examLesson.incorrect_answers
                    }
                  />
                </Cell>
                <Cell style={{ textAlign: "right" }}>
                  <NumberInput
                    containerStyle={INPUT_CONTAINER}
                    disabled
                    type="number"
                    value={examLesson.net_value}
                  />
                </Cell>
                <Cell>
                  <Warning examLesson={examLesson} />
                </Cell>
              </Row>
            ))}
            <Row>
              <Cell></Cell>
              <Cell style={{ fontWeight: "bold" }}>TOPLAM</Cell>
              <Cell style={{ textAlign: "right" }}>
                {getTotalInArray(exam.lessons, "question_count")}
              </Cell>
              <Cell style={{ textAlign: "right" }}>
                {getTotalInArray(exam.lessons, "correct_answers")}
              </Cell>
              <Cell style={{ textAlign: "right" }}>
                {getTotalInArray(exam.lessons, "incorrect_answers")}
              </Cell>
              <Cell style={{ textAlign: "right" }}>
                {getTotalInArray(exam.lessons, "question_count") -
                  getTotalInArray(exam.lessons, "correct_answers") -
                  getTotalInArray(exam.lessons, "incorrect_answers")}
              </Cell>
              <Cell
                style={{
                  textAlign: "right",
                  color: "#6c5ce7",
                  fontWeight: "bold",
                }}
              >
                {getTotalInArray(exam.lessons, "net_value")}
              </Cell>
              <Cell></Cell>
            </Row>
          </Body>
        </TableContainer>
      </DashboardTemplate>
    </>
  );
}
