import React from "react";
import { install } from "resize-observer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import * as Validator from "validatorjs";
import AuthProvider from "./providers/AuthProvider";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import LoginPage from "./pages/Auth/Login";
import IndexPage from "./pages/Public/Index";
import DashboardPage from "./pages/Dashboard";
import TeacherListPage from "./pages/Teachers/List";
import ExamsListPage from "./pages/Exams/List";
import ExamsDetailPage from "./pages/Exams/Detail";
import GroupExamsListPage from "./pages/GroupExams/List";
import GroupExamsDetailPage from "./pages/GroupExams/Detail";
import StudentDetailPage from "./pages/Students/Detail";
import QuestionContainer from "./components/QuestionContainer";
import ExamLessonOptionPage from "./pages/Options/ExamLesson";
import ExamResultPage from "./pages/Results/Exam";
import StudentResultPage from "./pages/Results/Student";
import LessonListPage from "./pages/Results/Lesson";
import LessonDetailPage from "./pages/Results/Lesson/Detail";
import ExamsListResultPage from "./pages/Results/Exam/List";
import PlacementPage from "./pages/Students/Placement";
import { Provider } from "react-redux";
import store from "./store";

install();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://898ee42df365439aa62f3896983354e9@o948871.ingest.sentry.io/5897829",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export default function App() {
  Validator.useLang("tr");

  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <Switch>
            <PublicRoute path="/login">
              <LoginPage />
            </PublicRoute>
            <PublicRoute path="/r/e/:nanoId">
              <ExamResultPage />
            </PublicRoute>
            <PublicRoute path="/r/u/:nanoId/lessons/:lessonId">
              <LessonDetailPage />
            </PublicRoute>
            <PublicRoute path="/r/u/:nanoId/lessons">
              <LessonListPage />
            </PublicRoute>
            <PublicRoute path="/r/u/:nanoId/exams">
              <ExamsListResultPage />
            </PublicRoute>
            <PublicRoute path="/r/u/:nanoId">
              <StudentResultPage />
            </PublicRoute>
            <PrivateRoute path="/students/:id">
              <StudentDetailPage />
            </PrivateRoute>
            <PrivateRoute path="/teachers">
              <TeacherListPage />
            </PrivateRoute>
            <PrivateRoute path="/exams/:id">
              <ExamsDetailPage />
            </PrivateRoute>
            <PrivateRoute path="/exams">
              <ExamsListPage />
            </PrivateRoute>
            <PrivateRoute path="/group-exams/:id">
              <GroupExamsDetailPage />
            </PrivateRoute>
            <PrivateRoute path="/group-exams">
              <GroupExamsListPage />
            </PrivateRoute>
            <PrivateRoute path="/placement">
              <PlacementPage />
            </PrivateRoute>
            <PrivateRoute path="/options/exam-lessons">
              <ExamLessonOptionPage />
            </PrivateRoute>
            <PrivateRoute path="/students">
              <DashboardPage />
            </PrivateRoute>
            <PublicRoute path="/">
              <IndexPage />
            </PublicRoute>
          </Switch>
        </AuthProvider>
        <ToastContainer />
        <QuestionContainer />
      </Router>
    </Provider>
  );
}
