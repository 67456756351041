import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import Form from "../../../components/Form";
import ExamTypeInput from "../../../components/Form/ExamTypeInput";
import TeacherSelect from "../../../components/Form/TeacherSelect";
import StudentSelect from "../../../components/Form/StudentSelect";
import LessonSelect from "../../../components/Form/LessonSelect";
import SubjectSelect from "../../../components/Form/SubjectSelect";
import { EXAM_TYPES } from "../../../constants";
import API from "../../../axe-api/api";
import insert from "../../../axe-api/insert";
import styled from "styled-components";
import { useHistory } from "react-router";

const BASIC_RULES = {
  exam_type: "required",
  student_id: "required",
};

const LESSON_RULES = {
  ...BASIC_RULES,
  lesson_id: "required",
  teacher_id: "required",
  subject_id: "required",
};

const OPTION_FETCH_TYPES = [
  EXAM_TYPES.TYT,
  EXAM_TYPES.AYT_SAYISAL,
  EXAM_TYPES.AYT_EA,
  EXAM_TYPES.AYT_SOZEL,
  EXAM_TYPES.AYT_DIL,
];

const DefaultLessonBox = styled.div`
  padding-bottom: 10px;
`;

const DefaultLessonTitle = styled.h4`
  font-weight: 500;
  margin-bottom: 8 px;
  color: #333;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const DefaultLesson = styled.div`
  padding-left: 8px;
  margin-bottom: 8px;
`;

export default function NewExamForm({ onClose }) {
  const history = useHistory();
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [examLessonDefaults, setExamLessonDefaults] = useState([]);
  const [form, setForm] = useState({
    exam_type: null,
    student_id: null,
    lesson_id: null,
    teacher_id: null,
    subject_id: null,
  });

  useEffect(() => {
    const fetchExamLessonDefaults = async (exam_type) => {
      const { data } = await API("exam-lesson-defaults")
        .where("exam_type", "=", exam_type)
        .with("lesson{name}")
        .fetch({
          per_page: 100,
          page: 1,
        });
      setExamLessonDefaults(data);
    };

    if (OPTION_FETCH_TYPES.includes(form.exam_type)) {
      setForm({
        ...form,
        teacher_id: null,
        lesson_id: null,
        subject_id: null,
      });
      fetchExamLessonDefaults(form.exam_type);
    } else {
      setExamLessonDefaults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.exam_type]);

  const handleSubmit = async () => {
    const data = {
      exam_type: form.exam_type,
      student_id: form.student_id,
      lessons: [],
    };

    if (["QUIZ", "KONU_DENEMESI"].includes(form.exam_type)) {
      data.teacher_id = form.teacher_id;
      data.subject_id = form.subject_id;
      data.lessons.push(form.lesson_id);
    } else {
      data.lessons.push(...examLessonDefaults.map((item) => item.lesson_id));
    }

    setLoading(true);
    const { id } = await insert("exams", data);
    setLoading(false);
    history.push(`exams/${id}`);
    onClose();
  };

  const isLessonSelectOpen =
    form.exam_type && ["QUIZ", "KONU_DENEMESI"].includes(form.exam_type);

  const RULES = isLessonSelectOpen ? LESSON_RULES : BASIC_RULES;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        rules={RULES}
        state={form}
        setState={setForm}
        setValid={setValid}
      >
        <StudentSelect name="student_id" title="Öğrenci" isRequired />
        <ExamTypeInput name="exam_type" title="Sınav Türü" isRequired />
        {isLessonSelectOpen && (
          <LessonSelect
            name="lesson_id"
            title="Ders"
            isCreatable={false}
            isRequired
          />
        )}
        {isLessonSelectOpen && form.lesson_id && (
          <SubjectSelect
            name="subject_id"
            title="Konu"
            lessonId={form.lesson_id}
            isCreatable
            isRequired
          />
        )}
        {isLessonSelectOpen && (
          <TeacherSelect
            name="teacher_id"
            title="Ders Sorumlusu"
            isCreatable={false}
            isRequired
          />
        )}
        {examLessonDefaults.length > 0 && (
          <DefaultLessonBox>
            <DefaultLessonTitle>Varsayılan Dersler</DefaultLessonTitle>
            {examLessonDefaults.map((item, index) => (
              <DefaultLesson key={index}>• {item.lesson?.name}</DefaultLesson>
            ))}
          </DefaultLessonBox>
        )}
        <PrimaryButton
          type="submit"
          isLoading={isLoading}
          disabled={isValid === false}
        >
          Kaydet
        </PrimaryButton>
      </Form>
    </>
  );
}
