import React, { useEffect } from "react";
import styled from "styled-components";

const Link = styled.a`
  font-weight: 600;
  margin-right: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? "#5f5fcf" : "#333")};
`;

export default function Tab({ title, name, current, onClick, children }) {
  useEffect(() => {
    if (name === current) {
      onClick(name, children);
    }
  }, [name, current, children, onClick]);

  return (
    <Link onClick={() => onClick(name, children)} active={name === current}>
      {title}
    </Link>
  );
}
