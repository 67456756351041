import React from "react";
import DBSelect from "../DBSelect";
import API from "../../../axe-api/api";
import { debounce } from "lodash";

export const toSelectItem = (item) => {
  return {
    value: item.id,
    label: item.name,
  };
};

export default function StudentSelect({
  defaultValue,
  isRequired = false,
  ...props
}) {
  const loadOptions = debounce(async (searchText, callback) => {
    const { data } = await API("students")
      .select(["id", "name"])
      .fulltext("name", searchText)
      .fetch({ per_page: 25, page: 1 });
    callback(data.map(toSelectItem));
  }, 300);

  const onCreate = async ({ value }) => {
    return await API("students").insert({
      name: value,
    });
  };

  return (
    <DBSelect
      resource="students"
      defaultValue={defaultValue && toSelectItem(defaultValue)}
      isRequired={isRequired}
      {...props}
      isCreatable={false}
      loadOptions={loadOptions}
      onCreate={onCreate}
    />
  );
}
