import React from "react";
import styled from "styled-components";

export const PageNav = styled.ul`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #535357;
  margin: 0px 0px 10px 0px;
  list-style-type: none;
  padding-left: 0px;
`;

const PageLinkContainer = styled.li`
  display: inline;

  &:after {
    content: " › ";
  }

  :last-child {
    &:after {
      content: unset;
    }
  }
`;

const PageAnchor = styled.a`
  cursor: pointer;
  &:hover {
    color: #666;
  }
`;

export const PageLink = ({ children, onClick }) => {
  return (
    <PageLinkContainer>
      <PageAnchor onClick={onClick}>{children}</PageAnchor>
    </PageLinkContainer>
  );
};

export const ItemLink = styled.a`
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  color: #535357;
  margin: 0px;
  padding: 12px 10px;
  border: 1px solid #f6f6f6;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;

  & small {
    display: block;
    font-weight: 400;
    color: #999;
    padding-top: 8px;
  }
`;

const EmptyBoxContainer = styled.div`
  display: block;
  font-family: Roboto;
  font-style: normal;
  background: #f7f6e4;
  color: #535357;
  margin: 0px;
  padding: 12px 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const EmptyBox = ({ array }) => {
  if (array.length) {
    return "";
  }

  return <EmptyBoxContainer>Gösterilecek kayıt yok.</EmptyBoxContainer>;
};
