import styled from "styled-components";

const StyledLink = styled.a`
  color: #333;
  text-decoration: none;

  &:hover {
    color: #6a6acc;
  }
`;

export default function ExternalLink({ children, ...props }) {
  return (
    <StyledLink rel="noreferrer" target="_blank" {...props}>
      {children}
    </StyledLink>
  );
}
