import styled from "styled-components";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, PieController } from "chart.js";
import ResultLine from "./ResultLine";

ChartJS.register(ArcElement, PieController);

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.25);
  border-radius: 10px;
  margin-bottom: 15px;
`;

const HeaderBar = styled.div`
  background: #546de5;
  border-radius: 10px 10px 0px 0px;
  color: white;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
`;

const LessonName = styled.div``;

const QuestionCount = styled.div`
  font-weight: normal;
  font-size: 16px;
  padding-top: 2px;
`;

const Body = styled.div`
  padding: 16px;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px dashed #596275;
  padding-bottom: 10px;
`;

const AnswersBox = styled.div`
  width: 100%;
`;

const GraphBox = styled.div`
  max-width: 100px;
  margin-top: -15px;
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
`;

const SuccessRate = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: rgb(89, 98, 117);
`;

const NetValueBox = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #5452cd;
`;

const getSuccessRate = (examLesson) => {
  if (!examLesson || !examLesson.success_rate) {
    return 0;
  }
  return examLesson.success_rate.toFixed(0);
};

const getLessonNameById = (lessons, lessonId) => {
  const found = lessons.find((i) => i.id === lessonId);
  if (found) {
    return found.name;
  }
  throw new Error(`The lesson not found: ${lessonId}`);
};

export default function LessonBox({ examLesson, lessons }) {
  const emptyQuestionCount =
    examLesson.question_count -
    examLesson.correct_answers -
    examLesson.incorrect_answers;

  return (
    <Container>
      <HeaderBar>
        <LessonName>
          {getLessonNameById(lessons, examLesson.lesson_id)}
        </LessonName>
        <QuestionCount>{examLesson.question_count} Soru</QuestionCount>
      </HeaderBar>
      <Body>
        <InfoBox>
          <AnswersBox>
            <ResultLine
              title="Doğru Cevap"
              value={examLesson.correct_answers}
              color="#63CDDA"
            />
            <ResultLine
              title="Yanlış Cevap"
              value={examLesson.incorrect_answers}
              color="#CF6A87"
            />
            <ResultLine
              title="Boş Sayısı"
              value={emptyQuestionCount}
              color="#596275"
            />
          </AnswersBox>
          <GraphBox>
            <Chart
              type="pie"
              data={{
                datasets: [
                  {
                    data: [
                      emptyQuestionCount,
                      examLesson.incorrect_answers,
                      examLesson.correct_answers,
                    ],
                    backgroundColor: [
                      "rgb(89, 98, 117)",
                      "rgb(207, 106, 135)",
                      "rgb(99, 205, 218)",
                    ],
                    borderColor: [
                      "rgb(89, 98, 117)",
                      "rgb(207, 106, 135)",
                      "rgb(99, 205, 218)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </GraphBox>
        </InfoBox>
        <SummaryContainer>
          <SuccessRate>%{getSuccessRate(examLesson)} BAŞARI</SuccessRate>
          <NetValueBox>{examLesson.net_value} NET</NetValueBox>
        </SummaryContainer>
      </Body>
    </Container>
  );
}
