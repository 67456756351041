import axios from "axios";

const patch = async (resource, data) => {
  const response = await axios.patch(`${resource}`, data);
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

export default patch;
