import dayjs from "dayjs";
import { SINGLE_LESSON_EXAMS, EXAM_TYPE_LABELS } from "../constants";

const toExamDescription = (exam) => {
  let subjectTitle = "";
  if (exam.subject) {
    subjectTitle = `(${exam.subject?.name})`;
  }

  let lessonName = "";
  if (
    SINGLE_LESSON_EXAMS.includes(exam.exam_type) &&
    exam.lessons &&
    exam.lessons.length === 1
  ) {
    lessonName = exam.lessons[0]?.lesson?.name;
  }

  return [
    lessonName,
    `${EXAM_TYPE_LABELS[exam.exam_type]}`,
    subjectTitle,
    "Sonuçları",
    `(${dayjs(exam.created_at).format("DD-MM-YYYY")})`,
  ]
    .join(" ")
    .replace(/\s\s+/g, " ")
    .trim();
};

export const getReportPageDomain = () => {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "http://localhost:3000";
  }

  return "https://matmaxozelders.com";
};

export const getExamShareLink = (exam) => {
  const domain = getReportPageDomain();
  return [
    `${exam.student?.name}`,
    toExamDescription(exam),
    `${domain}/r/e/${exam.nano_id}`,
  ].join("\n");
};

export const toFilledNumber = (number = "") => {
  number = number.toString();

  for (let index = number.length; index < 3; index++) {
    number = "0" + number;
  }

  return number;
};
