import styled from "styled-components";
import Navbar from "../Navbar";

const Container = styled.div``;

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #333;
  font-weight: 600;
  font-size: 30px;
`;

export default function DashboardTemplate({ children }) {
  return (
    <Container>
      <Navbar />
      <Content>{children}</Content>
    </Container>
  );
}
