import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CHART_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },

  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const ChartTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export default function SuccessChart({ title, data }) {
  return (
    <Container>
      <ChartTitle>{title}</ChartTitle>
      <Line
        options={CHART_OPTIONS}
        data={{
          labels: data.map((item, index) => `${index + 1}. Sınav`),
          datasets: [
            {
              label: "Başarı yüzdesi",
              data,
              borderColor: "rgb(95, 95, 207)",
              backgroundColor: "rgb(95, 95, 207)",
            },
          ],
        }}
      />
    </Container>
  );
}
