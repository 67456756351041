import axios from "axios";

class Query {
  constructor(url) {
    this.url = url;
    this.fields = [];
    this.wheres = [];
    this.sorts = [];
    this.withValue = null;
  }

  select(value) {
    if (Array.isArray(value)) {
      this.fields.push(...value);
    } else {
      this.fields.push(value);
    }
    return this;
  }

  sort(value) {
    this.sorts.push(value);
    return this;
  }

  with(value) {
    this.withValue = value;
    return this;
  }

  where(column, operator, value, prefix = "") {
    if (value === undefined) {
      value = operator;
      operator = "";
    }

    if (operator === "=") {
      operator = "";
    }

    this.wheres.push({
      [`${prefix}${column}${operator}`]: value,
    });

    return this;
  }

  whereLike(column, value) {
    return this.where(column, ".$like", value);
  }

  orWhereLike(column, value) {
    return this.where(column, ".$like", value, "$or.");
  }

  orWhere(column, operator, value) {
    return this.where(column, operator, value, "$or.");
  }

  fulltext(column, value) {
    if (value) {
      return this.whereLike(column, `*${value}*`);
    }
    return this;
  }

  async fetch({ page, per_page = 10 }) {
    const { data } = await axios.get(this.url, {
      params: {
        q: this.wheres.length > 0 ? JSON.stringify(this.wheres) : undefined,
        fields: this.fields.length > 0 ? this.fields.join(",") : undefined,
        with: this.withValue ? this.withValue : undefined,
        sort: this.sorts.length > 0 ? this.sorts.join(",") : undefined,
        page: page || 1,
        per_page: per_page || 10,
      },
    });
    return data;
  }

  async find(id) {
    const { data } = await axios.get(`${this.url}/${id}`, {
      params: {
        q: JSON.stringify(this.wheres),
        fields: this.fields.join(","),
        with: this.withValue,
      },
    });
    return data;
  }

  async insert(form) {
    const { data } = await axios.post(this.url, form);
    return data;
  }
}

const generator = (resource) => {
  return new Query(resource);
};

export default generator;
