import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

const override = css`
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  margin: auto;
  display: block;
`;

export default function Loader() {
  return (
    <div>
      <BounceLoader color="#5f5fcf" css={override} loading={true} size={150} />
    </div>
  );
}
