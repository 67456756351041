import { ADD_QUESTION, REMOVE_QUESTION } from "../types";

export const addQuestion = (title, question, callback) => {
  return {
    type: ADD_QUESTION,
    payload: {
      title,
      question,
      callback,
    },
  };
};

export const removeQuestion = (title, question) => {
  return {
    type: REMOVE_QUESTION,
  };
};
