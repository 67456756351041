import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const StyledHead = styled.thead`
  font-weight: 600;
  color: #333;
  font-size: 14px;

  & tr {
    & td {
      padding: 10px 10px;
      border-bottom: 2px solid #d1d1d1;
    }
  }
`;

export const Head = ({ children }) => {
  return (
    <StyledHead>
      <tr>{children}</tr>
    </StyledHead>
  );
};

export const Title = styled.td``;

export const Body = styled.tbody``;

export const Row = styled.tr`
  &:hover {
    background-color: #fafafa;
  }
`;

export const Cell = styled.td`
  color: rgba(0, 0, 0, 0.68);
  font-weight: 500;
  padding: 15px 10px;
  border-bottom: 1px solid #f1f1f1;
`;
