import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import useLocationQuery, { getQueryValue } from "../../hooks/useLocationQuery";

const Container = styled.div``;

const MenuContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 10px 0px;
`;

const ContentContainer = styled.div`
  padding: 10px 0px;
`;

export default function TabContent({ defaultValue, children }) {
  const [current, setCurrent] = useState(getQueryValue("tab") || defaultValue);
  const [content, setContent] = useState("");
  const query = useLocationQuery();
  const history = useHistory();

  const onClick = (name, children) => {
    setCurrent(name);
    setContent(children);
  };

  useEffect(() => {
    query.set("tab", current);
    history.push({ search: query.toString() });
    // eslint-disable-next-line
  }, [current]);

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { onClick, current });
  });

  return (
    <Container>
      <MenuContainer>{childrenWithProps}</MenuContainer>
      <ContentContainer>{content}</ContentContainer>
    </Container>
  );
}
