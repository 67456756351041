import React from "react";
import TextInput from "../TextInput";

export default function SearchInput({ onChange, ...props }) {
  return (
    <TextInput
      placeholder="Ara..."
      containerStyle={{
        marginBottom: "0px",
        marginRight: "10px",
        marginLeft: "auto",
      }}
      onChange={onChange}
      {...props}
    />
  );
}
