import React from "react";
import styled from "styled-components";
import logo from "../Exam/logo.jpg";
import PageLink from "../../../components/PageLink";
import studentPng from "../../../images/student.png";
import bookPng from "../../../images/book.png";
import examPng from "../../../images/exam.png";
import { useHistory, useRouteMatch } from "react-router-dom";

const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  left: 0px;
  right: 0px;
  padding: 0px;
`;

const Header = styled.div`
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  height: 70px;
  box-sizing: border-box;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  background: white;

  & div.container {
    max-width: 1000px;
    margin: auto;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Logo = styled.img`
  display: block;
  height: 70px;
  width: 70px;
`;

const Title = styled.h1`
  margin: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  color: #535357;
`;

const Content = styled.div`
  margin-top: 85px;
  margin-bottom: 15px;
  padding: 0px 10px 55px 10px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  background: #fafafa;
  min-height: 50px;
  width: 100%;
  border-top: 1px solid #f8f8f8;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  margin: 0px;
  box-sizing: border-box;
  gap: 10px;
  padding: 0px 10px;

  & div.container {
    width: 100%;
    margin: auto;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function Template({ children, student }) {
  const history = useHistory();
  const activation = {
    exam: useRouteMatch("/r/e/:nanoId"),
    examList: useRouteMatch("/r/u/:nanoId/exams"),
    lessonDetail: useRouteMatch("/r/u/:nanoId/lessons/:lessonId"),
    lessonList: useRouteMatch("/r/u/:nanoId/lessons"),
    student: useRouteMatch("/r/u/:nanoId"),
  };

  const handleStudentClick = () => {
    history.push(`/r/u/${student.nano_id}`);
  };

  const handleLessonClick = () => {
    history.push(`/r/u/${student.nano_id}/lessons`);
  };

  const handleExamClick = () => {
    history.push(`/r/u/${student.nano_id}/exams`);
  };

  return (
    <>
      <Header>
        <div className="container">
          <Logo src={logo} />
          <Title>{student?.name || "MatMax Karne"}</Title>
        </div>
      </Header>
      <Content>
        <Container>{children}</Container>
      </Content>
      <Footer>
        <div className="container">
          <PageLink
            icon={studentPng}
            onClick={handleStudentClick}
            isActive={activation.student && activation.student.isExact}
          >
            Öğrenci
          </PageLink>
          <PageLink
            icon={bookPng}
            onClick={handleLessonClick}
            isActive={
              (activation.lessonDetail && activation.lessonDetail.isExact) ||
              (activation.lessonList && activation.lessonList.isExact)
            }
          >
            Ders
          </PageLink>
          <PageLink
            icon={examPng}
            onClick={handleExamClick}
            isActive={
              (activation.exam && activation.exam.isExact) ||
              (activation.examList && activation.examList.isExact)
            }
          >
            Sınav
          </PageLink>
        </div>
      </Footer>
    </>
  );
}
