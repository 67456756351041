import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import Dropdown, { DropdownItem } from "../../../components/Form/Dropdown";
import NewOptionForm from "./NewOptionForm";
import { useQueryFilters } from "../../../hooks";
import Drawer from "../../../components/Drawer";
import API from "../../../axe-api/api";
import { EXAM_TYPE_LIST } from "../../../constants";
import deleteRequest from "../../../axe-api/deleteRequest";
import { addQuestion } from "../../../store/actions/question";
import SearchInput from "../../../components/Form/SearchInput";
import Loader from "../../../components/Loader";

const OPTIONS = EXAM_TYPE_LIST.find((i) => i.label === "Denemeler").options;

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const getExamLabel = (type) => {
  const item = OPTIONS.find((i) => i.value === type);
  return item?.label;
};

export default function ExamLessonOptionPage() {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(null);
  const [options, setOptions] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });

  const handleAddButton = () => {
    setDrawer({
      title: "Ekle",
      component: (
        <NewOptionForm onClose={() => setDrawer(null)} refetch={fetchOptions} />
      ),
    });
  };

  const fetchOptions = async () => {
    const query = API("exam-lesson-defaults");

    if (filters.searchText) {
      query.whereLike("lesson.name", filters.searchText);
    }

    const data = await query.with("lesson{name}").fetch(filters);
    setOptions(data);
  };

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  const deleteOption = async (id) => {
    await deleteRequest(`exam-lesson-defaults/${id}`);
    fetchOptions();
  };

  const handleDeleteButton = async ({ id }) => {
    dispatch(
      addQuestion(
        "Emin misin?",
        "Bu kaydı silmek istediğinden emin misin?",
        () => deleteOption(id)
      )
    );
  };

  if (!options) return <Loader />;

  return (
    <>
      <Drawer data={drawer} onClose={setDrawer} />
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Sınav-Ders Eşleşmeleri</PageTitle>
          <SearchInput onChange={debouncedSearchChange} />
          <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title style={{ width: "1%" }}></Title>
            <Title>Sınav Türü</Title>
            <Title>Ders</Title>
          </Head>
          <Body>
            {options.data.map((option, index) => (
              <Row key={index}>
                <Cell>
                  <Dropdown>
                    <DropdownItem onClick={() => handleDeleteButton(option)}>
                      Sil
                    </DropdownItem>
                  </Dropdown>
                </Cell>
                <Cell>{getExamLabel(option.exam_type)}</Cell>
                <Cell>{option.lesson?.name}</Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
        <Pagination
          data={options.pagination}
          filters={filters}
          setFilters={setFilters}
        />
      </DashboardTemplate>
    </>
  );
}
