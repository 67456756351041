import styled from "styled-components";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import TabContent from "../../../components/Tab/TabContent";
import Tab from "../../../components/Tab/Tab";
import { ExamTab, InfoTab, LessonTab } from "./Tabs";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function StudentDetail() {
  return (
    <DashboardTemplate>
      <ActionBox>
        <PageTitle>Öğrenci Profili</PageTitle>
      </ActionBox>
      <TabContent defaultValue="info">
        <Tab name="info" title="Bilgiler">
          <InfoTab />
        </Tab>
        <Tab name="lessons" title="Dersler">
          <LessonTab />
        </Tab>
        <Tab name="exams" title="Sınavlar">
          <ExamTab />
        </Tab>
      </TabContent>
    </DashboardTemplate>
  );
}
