import React, { useState, useRef } from "react";
import styled from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";

const Container = styled.div`
  position: relative;
`;

const TitleLink = styled.button`
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 16px;
  margin-right: 40px;
  color: #666;
  padding-top: 5px;
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover {
    color: #464646;
  }
`;

const MenuContent = styled.div`
  background: red;
  position: absolute;
  left: 0px;
  top: 30px;
  min-width: 200px;
  padding: 12px 8px;
  border: 1px solid #f1f1f1;
  background-color: white;
  border-radius: 3px;
`;

export default function Dropdown({ title, children }) {
  const titleRef = useRef();
  const [isActive, setActive] = useState(false);

  useClickOutside(titleRef, () => {
    setTimeout(() => {
      if (isActive) {
        setActive(false);
      }
    }, 300);
  });

  const handleClick = () => {
    setActive(true);
  };

  return (
    <Container>
      <TitleLink type="button" onClick={handleClick} ref={titleRef}>
        {title}
      </TitleLink>
      {isActive && <MenuContent>{children}</MenuContent>}
    </Container>
  );
}
