import styled from "styled-components";
import { useParams } from "react-router";
import dayjs from "dayjs";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import { useEffect, useState } from "react";
import API from "../../../axe-api/api";
import { EXAM_TYPE_LABELS } from "../../../constants";
import Loader from "../../../components/Loader";
import TabContent from "../../../components/Tab/TabContent";
import Tab from "../../../components/Tab/Tab";
import { AnswerKeyTab, UploadTab, ResultsTab } from "./Tabs";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DateBox = styled.span`
  font-size: 18px;
  color: #999;
`;

export default function ExamsDetailPage() {
  const { id: examId } = useParams();
  const [exam, setExam] = useState(null);

  const fetchExam = async () => {
    const data = await API(`group-exams/${examId}`)
      .with("box1,box2,box3,box4")
      .fetch({ page: 1, per_page: 100 });
    setExam(data);
  };

  useEffect(() => {
    fetchExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!exam) return <Loader />;

  return (
    <>
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>
            Toplu Sınav: {EXAM_TYPE_LABELS[exam.exam_type]}
            <DateBox>{dayjs(exam.created_at).format("DD-MM-YYYY")}</DateBox>
          </PageTitle>
        </ActionBox>
        <TabContent defaultValue="upload">
          <Tab name="upload" title="Sonuç Yükle">
            <UploadTab exam={exam} />
          </Tab>
          <Tab name="results" title="Sonuçlar">
            <ResultsTab exam={exam} />
          </Tab>
          <Tab name="answerKey" title="Cevap Anahtarı">
            <AnswerKeyTab exam={exam} refetch={fetchExam} />
          </Tab>
        </TabContent>
      </DashboardTemplate>
    </>
  );
}
