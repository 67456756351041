import React, { useEffect } from "react";
import styled from "styled-components";
import * as Validator from "validatorjs";

const Container = styled.form`
  padding-top: 20px;
`;

export default function Form({
  children,
  state,
  setState,
  setValid,
  rules = {},
  onSubmit = () => {},
  ...props
}) {
  useEffect(() => {
    const validation = new Validator(state, rules);
    setValid(validation.passes());
  }, [state, rules, setValid]);

  useEffect(() => {
    const validation = new Validator(state, rules);
    setValid(validation.passes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = ({ name, value }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleLocalSubmit = (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child) {
      return child;
    }
    return React.cloneElement(child, { onChange });
  });

  return (
    <Container onSubmit={handleLocalSubmit} {...props}>
      {childrenWithProps}
    </Container>
  );
}
