import React, { useState, useRef } from "react";
import styled from "styled-components";
import { CogIcon } from "../../Icons";
import useClickOutside from "../../../hooks/useClickOutside";

const Container = styled.div`
  display: inline;
  position: relative;
`;

const ActionButton = styled.button`
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 4px 6px 2px 6px;
  cursor: pointer;
  color: #555;

  &:hover {
    color: #333;
  }

  &:focus {
    outline: none;
  }
`;

const MenuBox = styled.div`
  background: white;
  position: absolute;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #eee;
  padding: 6px 0px;
  z-index: 999;
`;

const ItemButton = styled.button`
  background: transparent;
  display: block;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 10px 8px;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  font-family: "Inter", sans-serif;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #f8f8f8;
    color: #5f5fcf;
  }
`;

export const DropdownItem = ({ children, ...props }) => {
  return (
    <ItemButton type="button" {...props}>
      {children}
    </ItemButton>
  );
};

export default function Dropdown({ children }) {
  const menuBoxRef = useRef();
  const [isActive, setActive] = useState(false);
  useClickOutside(menuBoxRef, () => {
    if (isActive) {
      setActive(false);
    }
  });

  const handleButtonClick = () => {
    setActive(!isActive);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: (event) => {
        setActive(false);
        child.props.onClick(event);
      },
    });
  });

  return (
    <Container>
      <ActionButton type="button" onClick={handleButtonClick}>
        <CogIcon />
      </ActionButton>
      {isActive && <MenuBox ref={menuBoxRef}>{childrenWithProps}</MenuBox>}
    </Container>
  );
}
