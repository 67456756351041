import { useLocation } from "react-router";

export const getQueryValue = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export default () => {
  return new URLSearchParams(useLocation().search);
};
