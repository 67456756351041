import { useCallback } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import Drawer from "../../../components/Drawer";
import { useEffect, useState } from "react";
import NewTeacherForm from "./NewTeacherForm";
import API from "../../../axe-api/api";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { useQueryFilters } from "../../../hooks";
import PhoneDisplay from "../../../components/Displays/PhoneDisplay";
import SearchInput from "../../../components/Form/SearchInput";
import Dropdown, { DropdownItem } from "../../../components/Form/Dropdown";
import Loader from "../../../components/Loader";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function TeacherListPage() {
  const [drawer, setDrawer] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });

  const handleAddButton = () => {
    setDrawer({
      title: "Ekle",
      component: (
        <NewTeacherForm
          onClose={() => setDrawer(null)}
          refetch={fetchTeachers}
        />
      ),
    });
  };

  const fetchTeachers = async () => {
    const query = API("teachers");

    if (filters.searchText) {
      query
        .whereLike("name", filters.searchText)
        .orWhereLike("phone", filters.searchText)
        .orWhereLike("email", filters.searchText);
    }

    const data = await query.fetch(filters);
    setTeachers(data);
  };

  useEffect(() => {
    fetchTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  const handleEdit = (teacher) => {
    setDrawer({
      title: "Güncelle",
      component: (
        <NewTeacherForm
          onClose={() => setDrawer(null)}
          refetch={fetchTeachers}
          teacher={teacher}
        />
      ),
    });
  };

  if (!teachers) return <Loader />;

  return (
    <>
      <Drawer data={drawer} onClose={setDrawer} />
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Öğretmenler</PageTitle>
          <SearchInput onChange={debouncedSearchChange} />
          <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title style={{ width: "1%" }}></Title>
            <Title>Ad Soyad</Title>
            <Title>Telefon</Title>
            <Title>E-posta</Title>
          </Head>
          <Body>
            {teachers.data.map((teacher, index) => (
              <Row key={index}>
                <Cell>
                  <Dropdown>
                    <DropdownItem onClick={() => handleEdit(teacher)}>
                      Düzenle
                    </DropdownItem>
                  </Dropdown>
                </Cell>
                <Cell>{teacher.name}</Cell>
                <Cell>
                  <PhoneDisplay>{teacher.phone}</PhoneDisplay>
                </Cell>
                <Cell>{teacher.email}</Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
        <Pagination
          data={teachers.pagination}
          filters={filters}
          setFilters={setFilters}
        />
      </DashboardTemplate>
    </>
  );
}
