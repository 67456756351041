import { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AuthContext from "../../providers/AuthContext";
import Dropdown from "./Dropdown";
import logo from "../../images/logo.jpeg";

const Container = styled.div`
  box-shadow: 0px 1px 10px #e5e5e5;
  margin-bottom: 20px;
`;

const Box = styled.div`
  background-color: white;
  max-width: 1200px;
  margin: auto;
  padding: 24px 0px;
  box-sizing: border-box;
  display: flex;
`;

const NavbarLink = styled(Link)`
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: #464646;
  }
`;

const Title = styled(NavbarLink)`
  color: #5f5fcf;
  font-size: 22px;
  font-weight: 700;
  margin-right: 20px;
  display: flex;
`;

const Navigator = styled(NavbarLink)`
  color: #666;
  padding-top: 5px;
  margin-right: 20px;
  font-weight: 600;
`;

const SubNavigator = styled(Navigator)`
  font-size: 15px;
  font-weight: 500;
`;

const LogoutLink = styled.a`
  color: #666;
  padding-top: 5px;
  margin-right: 20px;
  font-weight: 600;
  margin-left: auto;
  text-decoration: none;
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #464646;
  }
`;

const LogoImage = styled.img`
  height: 60px;
  margin-top: -15px;
`;

export default function Navbar() {
  const authContext = useContext(AuthContext);

  return (
    <Container>
      <Box>
        <Title to="/students">
          <LogoImage src={logo} />
        </Title>
        <Navigator to="/students">Öğrenciler</Navigator>
        <Navigator to="/placement">Sıralama</Navigator>
        <Navigator to="/teachers">Öğretmenler</Navigator>
        <Navigator to="/exams">Sınavlar</Navigator>
        <Navigator to="/group-exams">Toplu Sınav</Navigator>
        <Dropdown title="Ayarlar">
          <SubNavigator to="/options/exam-lessons">
            Sınav-Ders Eşleşmeleri
          </SubNavigator>
        </Dropdown>
        <LogoutLink onClick={authContext.logout}>Oturumu Kapat</LogoutLink>
      </Box>
    </Container>
  );
}
