import { useState } from "react";

const DEFAULT_VALUES = {
  page: 1,
  per_page: 10,
};

export default function useQueryFilters(data = {}) {
  const [filters, setFilters] = useState({
    ...DEFAULT_VALUES,
    ...data,
  });
  return [filters, setFilters];
}
