export const GRADUATION_TYPE = {
  GRADUATE: "GRADUATE",
  STUDENT: "STUDENT",
};

export const STUDENT_ACTIVATION_TYPES = [
  { value: 1, label: "Aktif" },
  { value: 0, label: "Pasif" },
];

export const GRADUATION_TITLES = [
  { value: GRADUATION_TYPE.GRADUATE, label: "Mezun" },
  { value: GRADUATION_TYPE.STUDENT, label: "Öğrenci" },
];

export const CLASS_YEARS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

export const EXAM_TYPES = {
  QUIZ: "QUIZ",
  KONU_DENEMESI: "KONU_DENEMESI",
  TYT: "TYT",
  AYT_SAYISAL: "AYT_SAYISAL",
  AYT_EA: "AYT_EA",
  AYT_SOZEL: "AYT_SOZEL",
  AYT_DIL: "AYT_DIL",
};

export const SINGLE_LESSON_EXAMS = [EXAM_TYPES.QUIZ, EXAM_TYPES.KONU_DENEMESI];

export const EXAM_TYPE_LABELS = {
  QUIZ: "Quiz",
  KONU_DENEMESI: "Konu Denemesi",
  TYT: "TYT Denemesi",
  AYT_SAYISAL: "AYT - Sayısal",
  AYT_EA: "AYT - Eşit Ağırlık",
  AYT_SOZEL: "AYT - Sözel",
  AYT_DIL: "AYT - Dil",
};

export const EXAM_TYPE_LIST = [
  {
    value: null,
    label: "Basit Sınavlar",
    options: [
      {
        value: EXAM_TYPES.QUIZ,
        label: "Quiz",
      },
      {
        value: EXAM_TYPES.KONU_DENEMESI,
        label: "Konu Denemesi",
      },
    ],
  },
  {
    value: null,
    label: "Denemeler",
    options: [
      { value: EXAM_TYPES.TYT, label: "TYT Denemesi" },
      {
        value: EXAM_TYPES.AYT_SAYISAL,
        label: "AYT - Sayısal",
      },
      {
        value: EXAM_TYPES.AYT_EA,
        label: "AYT - Eşit Ağırlık",
      },
      {
        value: EXAM_TYPES.AYT_SOZEL,
        label: "AYT - Sözel",
      },
      {
        value: EXAM_TYPES.AYT_DIL,
        label: "AYT - Dil",
      },
    ],
  },
];
