import { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import AuthContext from "../../providers/AuthContext";

export default function PrivateRoute({ children, ...props }) {
  const auth = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (auth.isAuth === false) {
      history.push("/");
    }
  }, [auth.isAuth, history]);

  if (!auth.isAuth) {
    return "redirecting...";
  }

  return <Route {...props}>{children}</Route>;
}
