import { useState } from "react";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import insert from "../../../axe-api/insert";
import Form from "../../../components/Form";
import LessonSelect from "../../../components/Form/LessonSelect";
import { EXAM_TYPE_LIST } from "../../../constants";
import SelectInput from "../../../components/Form/SelectInput";

const RULES = {
  exam_type: "required",
  lesson_id: "required",
};

const OPTIONS = EXAM_TYPE_LIST.find((i) => i.label === "Denemeler").options;

export default function NewOptionForm({ onClose, refetch }) {
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    exam_type: null,
    lesson_id: null,
  });

  const handleSubmit = async () => {
    setLoading(true);
    await insert("exam-lesson-defaults", form);
    setLoading(false);
    refetch();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      rules={RULES}
      state={form}
      setState={setForm}
      setValid={setValid}
    >
      <SelectInput name="exam_type" title="Ders" options={OPTIONS} />
      <LessonSelect name="lesson_id" title="Ders" />
      <PrimaryButton
        type="submit"
        isLoading={isLoading}
        disabled={isValid === false}
      >
        Kaydet
      </PrimaryButton>
    </Form>
  );
}
