import styled from "styled-components";
import Page from "./Page";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PrevPage = styled(Page)`
  margin-right: 6px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NextPage = styled(Page)`
  margin-left: 6px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const getVisiblePages = (currentPage, lastPage) => {
  let visiblePages = [currentPage];

  for (let index = 1; index < 3; index++) {
    visiblePages.unshift(currentPage - index);
    visiblePages.push(currentPage + index);
  }

  return visiblePages.filter((page) => page >= 1 && page <= lastPage);
};

export default function Pagination({
  data: { currentPage, lastPage },
  filters,
  setFilters,
}) {
  const handleClick = (page) => {
    setFilters({
      ...filters,
      page,
    });
  };

  if (lastPage === 0) {
    return "";
  }

  const visiblePages = getVisiblePages(currentPage, lastPage);

  return (
    <Container>
      <PrevPage
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Önceki
      </PrevPage>
      {!visiblePages.includes(1) && (
        <>
          <Page onClick={() => handleClick(1)}>1</Page>
          <Page disabled>...</Page>
        </>
      )}
      {visiblePages.map((page) => (
        <Page
          key={page}
          active={page === currentPage}
          onClick={() => handleClick(page)}
        >
          {page}
        </Page>
      ))}
      {!visiblePages.includes(lastPage) && (
        <>
          <Page disabled>...</Page>
          <Page onClick={() => handleClick(lastPage)}>{lastPage}</Page>
        </>
      )}
      <NextPage
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === lastPage}
      >
        Sonraki
      </NextPage>
    </Container>
  );
}
