import styled from "styled-components";
import { toFilledNumber } from "../../helpers/shared";

const Container = styled.span`
  background-color: #dedede;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 3px;
`;

export default function Number({ children }) {
  return <Container>{toFilledNumber(children)}</Container>;
}
