import * as Validator from "validatorjs";

export const getValidation = (value, title, rules) => {
  const validation = new Validator({ value }, { value: rules });
  validation.setAttributeNames({ value: title });
  return {
    isPassed: validation.passes(),
    errors: validation.errors.first("value"),
  };
};
