import React, { useContext } from "react";
import styled from "styled-components";
import logo from "../../images/logo.jpeg";
import AuthContext from "../../providers/AuthContext";
import InnerLink from "../../components/InnerLink";
import LinkButton from "../../components/Form/LinkButton";

const Container = styled.div`
  max-width: 1000px;
  left: 0px;
  right: 0px;
  margin: auto;
`;

const LogoContainer = styled.div`
  display: flex;
`;

const LogoImage = styled.img`
  left: 0px;
  right: 0px;
  margin: auto;
`;

const Title = styled.h1`
  font-family: "Inter", sans-serif;
  color: #363636;
  margin-top: 0px;
`;

const ContentBox = styled.article`
  text-align: center;

  & p {
    font-size: 18px;
    color: #555;
    font-weight: 500;
  }
`;

const StyledLink = styled(InnerLink)`
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.68);
`;

export default function IndexPage() {
  const auth = useContext(AuthContext);
  const { isAuth } = auth;

  return (
    <Container>
      <LogoContainer>
        <LogoImage src={logo} />
      </LogoContainer>
      <ContentBox>
        <Title>MatMax Özel Ders</Title>
        <p>Sakarya’da yüzyüze, Türkiye’de online özel dersin adresi.</p>
        <div style={{ paddingTop: "50px" }}>
          {isAuth && (
            <div>
              <StyledLink to="/students">Öğrenci Listesi</StyledLink>
              <StyledLink to="/exams">Sınavlar</StyledLink>
              <LinkButton onClick={auth.logout}>Oturumu Kapat</LinkButton>
            </div>
          )}
          {!isAuth && (
            <div>
              <StyledLink to="/login">Oturum Aç</StyledLink>
            </div>
          )}
        </div>
      </ContentBox>
    </Container>
  );
}
