import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../../components/Form/PrimaryButton";
import DeleteButton from "../../../../components/Form/DeleteButton";
import patch from "../../../../axe-api/patch";
import AnswerBox from "../../../../components/AnswerBox";
import { addQuestion } from "../../../../store/actions/question";

const Container = styled.div``;

const AnswerContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0px;
`;

const getFileSelector = () => {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept", "image/png, image/gif, image/jpeg");
  return fileSelector;
};

export default function AnswerKeyTab({ exam, refetch }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleFileSelection = async (files) => {
    try {
      setLoading(true);
      const [file] = files;
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.post(
        "https://optical.matmaxkids.com/read",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await patch(`group-exams/${exam.id}`, {
        answer_key: JSON.stringify(data.answers),
      });
      await refetch();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    const selector = getFileSelector();
    selector.onchange = () => {
      let files = Array.from(selector.files);
      handleFileSelection(files);
    };
    selector.click();
  };

  const handleDelete = async () => {
    setLoading(true);
    await patch(`group-exams/${exam.id}`, {
      answer_key: null,
      box_1: null,
      box_2: null,
      box_3: null,
      box_4: null,
    });
    await refetch();
    setLoading(false);
  };

  const askDeleteQuestion = () => {
    dispatch(
      addQuestion(
        "Emin misin?",
        "Cevap anahtarını silmek istediğinden emin misin?",
        handleDelete
      )
    );
  };

  if (exam.answer_key === null) {
    return (
      <Container>
        <PrimaryButton onClick={handleClick} isLoading={loading}>
          Cevap Anahtarını Yükle
        </PrimaryButton>
      </Container>
    );
  }

  const answers = JSON.parse(exam.answer_key);

  return (
    <Container>
      <DeleteButton onClick={askDeleteQuestion} isLoading={loading}>
        Cevap Anahtarını Sil
      </DeleteButton>

      <AnswerContainer>
        <AnswerBox
          index={1}
          refetch={refetch}
          exam={exam}
          items={answers.box1}
        />
        <AnswerBox
          index={2}
          refetch={refetch}
          exam={exam}
          items={answers.box2}
        />
        <AnswerBox
          index={3}
          refetch={refetch}
          exam={exam}
          items={answers.box3}
        />
        <AnswerBox
          index={4}
          refetch={refetch}
          exam={exam}
          items={answers.box4}
        />
      </AnswerContainer>
    </Container>
  );
}
