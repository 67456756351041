import React, { useState, useCallback } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { getValidation } from "../../../helpers/forms";
import { InputContainer, InputLabel, InputError } from "../InputStyles";
import RequiredMark from "../../RequiredMark";

export default function DBSelect({
  name,
  title,
  isCreatable = true,
  isRequired = false,
  rules = null,
  onChange,
  containerStyle = {},
  loadOptions,
  onCreate,
  ...props
}) {
  const [isLoading, setLoading] = useState(false);
  const [, setValid] = useState(null);
  const [error, setError] = useState(null);

  const handleSelectOption = ({ value }) => {
    if (rules && name && (rules || rules[name])) {
      const { isPassed, errors } = getValidation(
        value,
        title,
        rules[name] || rules
      );
      setValid(isPassed);
      setError(errors);
      if (isPassed) {
        onChange({ name, value });
      }
    } else {
      onChange({ name, value });
    }
  };

  const handleCreateOption = async ({ value }) => {
    const { id } = await onCreate({ value });
    handleSelectOption({ value: id });
  };

  const handleChange = (value, type) => {
    if (type.action === "select-option") {
      handleSelectOption(value);
    } else if (type.action === "clear") {
      handleSelectOption({ value: null });
    } else if (type.action === "create-option") {
      handleCreateOption(value);
    }
  };

  const startLoading = useCallback(
    (searchText, callback) => {
      setLoading(true);
      loadOptions(searchText, (results) => {
        setLoading(false);
        callback(results);
      });
    },
    [loadOptions]
  );

  return (
    <InputContainer style={containerStyle}>
      {title && (
        <InputLabel>
          {title} {isRequired && <RequiredMark />}
        </InputLabel>
      )}
      <AsyncCreatableSelect
        isClearable
        isLoading={isLoading}
        cacheOptions
        defaultOptions
        onChange={handleChange}
        loadOptions={startLoading}
        loadingMessage={() => "Aranıyor..."}
        noOptionsMessage={() => "Aradığınız kayıt bulunamadı!"}
        isValidNewOption={(value) => isCreatable && value && value.length > 0}
        formatCreateLabel={(userInput) => `Yeni kayıt oluştur: "${userInput}"`}
        {...props}
      />
      {error && <InputError>{error}</InputError>}
    </InputContainer>
  );
}
