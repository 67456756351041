import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../../../components/Loader";
import Template from "../Template";
import { PageNav, PageLink, ItemLink, EmptyBox } from "../Shared";
import { EXAM_TYPE_LABELS } from "../../../constants";

const getLessonTitle = (studentLesson, lessons) => {
  const found = lessons.find((lesson) => lesson.id === studentLesson.lesson_id);
  return found?.name || "Bilinmeyen Ders";
};

const getSubjectTitle = (exam, subjects) => {
  const found = subjects.find((lesson) => lesson.id === exam.subject_id);
  return found?.name;
};

export default function LessonDetailPage() {
  const history = useHistory();
  const { nanoId, lessonId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getResults = async () => {
      const { data } = await axios.get(
        `results/student/${nanoId}/lessons/${lessonId}`
      );
      setData(data);
    };

    getResults();
  }, [nanoId, lessonId]);

  if (!data) return <Loader />;

  const { student, lessons, studentLesson, exams, subjects } = data;

  const handleClick = ({ nano_id }) => {
    history.push(`/r/e/${nano_id}`);
  };

  return (
    <Template student={student}>
      <PageNav>
        <PageLink
          onClick={() => history.push(`/r/u/${student.nano_id}/lessons`)}
        >
          Dersler
        </PageLink>
        <PageLink>{getLessonTitle(studentLesson, lessons)}</PageLink>
      </PageNav>
      <EmptyBox array={exams} />
      {exams.map((exam, index) => (
        <ItemLink key={index} onClick={() => handleClick(exam)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {EXAM_TYPE_LABELS[exam.exam_type]}{" "}
              {exam.subject_id && (
                <span>- {getSubjectTitle(exam, subjects)}</span>
              )}
            </div>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              %{exam.total_success_rate}
            </div>
          </div>
          <small>{dayjs(exam.created_at).format("DD/MM/YYYY")}</small>
        </ItemLink>
      ))}
    </Template>
  );
}
