import React from "react";
import Select from "react-select";
import RequiredMark from "../../RequiredMark";
import { InputContainer, InputLabel } from "../InputStyles";

export default function SelectInput({
  name,
  title,
  options = [],
  onChange,
  containerStyle = {},
  isRequired = false,
  ...props
}) {
  const handleChange = (option) => {
    let value = null;
    if (option !== null) {
      value = option.value;
    }
    onChange({ name, value });
  };

  return (
    <InputContainer style={containerStyle}>
      {title && (
        <InputLabel>
          {title} {isRequired && <RequiredMark />}
        </InputLabel>
      )}
      <Select options={options} onChange={handleChange} {...props} />
    </InputContainer>
  );
}
