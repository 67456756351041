import React, { useCallback, useState } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { CheckIcon } from "../Icons";
import patch from "../../axe-api/patch";

const Container = styled.div`
  display: flex;
`;

const InputBox = styled.div`
  width: 100%;
`;

const BulletContainer = styled.div`
  padding: 3px 14px 10px 0px;
`;

const Bullet = styled.div`
  height: 14px;
  width: 14px;
  background: #a29bfe;
  border-radius: 50%;
  transition: background-color 0.5s ease;

  &.success {
    background: #a29bfe;
  }

  &.editing {
    background: #aaa;
  }
`;

const BulletBox = ({ ...props }) => {
  return (
    <BulletContainer>
      <Bullet {...props}>
        <CheckIcon />
      </Bullet>
    </BulletContainer>
  );
};

export default function AutoSave({ resource, children }) {
  const [status, setStatus] = useState("success");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdate = useCallback(
    debounce(async ({ name, value }) => {
      await patch(resource, { [name]: value });
      setStatus("success");
    }, 300),
    []
  );

  const onChange = useCallback(
    async (args) => {
      if (status === "success") {
        setStatus("editing");
      }
      onUpdate(args);
    },
    [onUpdate, status]
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { onChange });
  });

  return (
    <Container>
      <BulletBox className={status} />
      <InputBox>{childrenWithProps}</InputBox>
    </Container>
  );
}
