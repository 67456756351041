import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  right: 0px;
  background-color: white;
  border-left: 1px solid #f1f1f1;
  padding: 30px;
  box-sizing: border-box;
  z-index: 999999;
`;

const Title = styled.h3`
  color: #333;
  font-weight: 600;
  font-size: 24px;
  margin: 0px 0px 20px 0px;
`;

const GrayBox = styled.div`
  background: black;
  opacity: 0.32;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const CloseButton = styled.button`
  background-color: white;
  float: right;
  width: 40px;
  height: 40px;
  border: 1px solid #f1f1f1;
  cursor: pointer;
  border-radius: 3px;
  margin-top: -5px;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export default function DrawerBox({ title, children, onClose }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <GrayBox />
      <Container>
        <Title>
          {title}
          <CloseButton type="button" onClick={onClose}>
            X
          </CloseButton>
        </Title>
        {children}
      </Container>
    </>
  );
}
