import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../components/Form/PrimaryButton";
import AnswerBox from "../../../../components/AnswerBox";

const Container = styled.div``;

const ErrorBox = styled.div`
  background-color: white;
  border: 1px solid #d14d4d;
  border-radius: 5px;
  padding: 20px;
  font-weight: 500;
`;

const AnswerContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0px;
`;

const getFileSelector = () => {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept", "image/png, image/gif, image/jpeg");
  return fileSelector;
};

export default function UploadTab({ exam }) {
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [pack, setPack] = useState(null);
  const answers = exam.answer_key ? JSON.parse(exam.answer_key) : null;
  const isActive =
    exam.answer_key && (exam.box_1 || exam.box_2 || exam.box_3 || exam.box_4);

  const handleFileSelection = async (files) => {
    try {
      setLoading(true);
      const [file] = files;
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.post(
        "https://optical.matmaxkids.com/read",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const exam_lessons = [];
      for (let index = 1; index <= 4; index++) {
        const currentBox = `box${index}`;
        if (exam[currentBox]) {
          const correctAnswers = answers[currentBox];
          const studentAnswers = data.answers[currentBox];
          const pack = {
            lesson_id: exam[currentBox].id,
            question_count: correctAnswers.length,
            correct_answers: 0,
            incorrect_answers: 0,
            net_value: 0,
            success_rate: 0,
          };

          correctAnswers.forEach((correctAnswer, index) => {
            const studentAnswer = studentAnswers[index];
            if (studentAnswer) {
              if (studentAnswer === correctAnswer) {
                pack.correct_answers++;
              } else {
                pack.incorrect_answers++;
              }
            }
          });

          pack.net_value = pack.correct_answers - pack.incorrect_answers / 4;
          pack.success_rate = parseFloat(
            ((100 * pack.net_value) / pack.question_count).toFixed(2)
          );

          exam_lessons.push(pack);
        }
      }

      const totalQuestion = exam_lessons.reduce(
        (total, examLesson) => total + examLesson.question_count,
        0
      );
      const total_net_value = exam_lessons.reduce(
        (total, examLesson) => total + examLesson.net_value,
        0
      );
      const pack = {
        exam_group_id: exam.id,
        student_id: data.studentNumber,
        exam_type: exam.exam_type,
        exam_lessons,
        total_net_value,
        total_success_rate: parseFloat(
          ((100 * total_net_value) / totalQuestion).toFixed(2)
        ),
      };
      setPack(pack);
      setStudentData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    const selector = getFileSelector();
    selector.onchange = () => {
      let files = Array.from(selector.files);
      handleFileSelection(files);
    };
    selector.click();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await axios.post("save-optical", pack);
      toast.success("Sonuçlar başarıyla kaydedildi.");
      setLoading(false);
      setStudentData(null);
      setPack(null);
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      toast.error("Bir hata meydana geldi.");
    }
  };

  if (!isActive) {
    return (
      <ErrorBox>
        Sonuç kağıdı yükleyebilmek için aşağıdaki adımların tamamlanması
        gerekiyor;
        <ul>
          <li>Cevap anahtarı yüklenmesi</li>
          <li>En az bir kutucuk için ders seçimi yapılmalı.</li>
        </ul>
      </ErrorBox>
    );
  }

  return (
    <>
      <Container>
        <div style={{ display: "flex", gap: "10px" }}>
          <PrimaryButton
            onClick={handleClick}
            isLoading={loading}
            disabled={!isActive}
          >
            Sonuç Yükle
          </PrimaryButton>
          {studentData && (
            <PrimaryButton
              onClick={handleSave}
              isLoading={loading}
              style={{ backgroundColor: "#6db587" }}
            >
              Kaydet
            </PrimaryButton>
          )}
        </div>

        {studentData && (
          <AnswerContainer>
            <AnswerBox
              index={1}
              isEditable={false}
              refetch={() => {}}
              exam={exam}
              items={studentData.answers.box1}
            />
            <AnswerBox
              index={2}
              isEditable={false}
              refetch={() => {}}
              exam={exam}
              items={studentData.answers.box2}
            />
            <AnswerBox
              index={3}
              isEditable={false}
              refetch={() => {}}
              exam={exam}
              items={studentData.answers.box3}
            />
            <AnswerBox
              index={4}
              isEditable={false}
              refetch={() => {}}
              exam={exam}
              items={studentData.answers.box4}
            />
          </AnswerContainer>
        )}
      </Container>
    </>
  );
}
