import { useState } from "react";
import axios from "axios";

class Query {
  constructor(url, setLoading) {
    this.url = url;
    this.setLoading = setLoading;
    this.clear();
  }

  clear() {
    this.fields = [];
    this.wheres = [];
    this.withValue = null;
    return this;
  }

  select(value) {
    if (Array.isArray(value)) {
      this.fields.push(...value);
    } else {
      this.fields.push(value);
    }
    return this;
  }

  with(value) {
    this.withValue = value;
    return this;
  }

  where(column, operator, value, prefix = "") {
    if (value === undefined) {
      value = operator;
      operator = "";
    }

    this.wheres.push({
      [`${prefix}${column}${operator}`]: value,
    });

    return this;
  }

  whereLike(column, value) {
    return this.where(column, ".$like", value);
  }

  orWhereLike(column, value) {
    return this.where(column, ".$like", value, "$or.");
  }

  orWhere(column, operator, value) {
    return this.where(column, operator, value, "$or.");
  }

  async fetch({ page, per_page = 10 }) {
    this.setLoading(true);
    const { data } = await axios.get(this.url, {
      params: {
        q: JSON.stringify(this.wheres),
        fields: this.fields.join(","),
        with: this.withValue,
        page,
        per_page,
      },
    });
    this.setLoading(false);
    return data;
  }

  async find(id) {
    this.setLoading(true);
    const { data } = await axios.get(`${this.url}/${id}`, {
      params: {
        q: JSON.stringify(this.wheres),
        fields: this.fields.join(","),
        with: this.withValue,
      },
    });
    this.setLoading(false);
    return data;
  }
}

const useQuery = (url) => {
  const [loading, setLoading] = useState(false);
  const [query] = useState(new Query(url, setLoading));

  const refect = async () => {
    return await query.fetch();
  };

  return { query, loading, refect };
};

export default useQuery;
