import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import API from "../../../../axe-api/api";
import { useQueryFilters } from "../../../../hooks";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../../components/Table";
import InnerLink from "../../../../components/InnerLink";
import ExternalLink from "../../../../components/ExternalLink";
import {
  getReportPageDomain,
  getExamShareLink,
} from "../../../../helpers/shared";
import { EXAM_TYPE_LABELS } from "../../../../constants";
import PrimaryButton from "../../../../components/Form/PrimaryButton";
import Loader from "../../../../components/Loader";

const LinkButton = styled(PrimaryButton)`
  padding: 4px 10px;
  height: 30px;
  font-size: 14px;
`;

const DOMAIN = getReportPageDomain();

export default function ExamTab() {
  const { id } = useParams();
  const [exams, setExams] = useState(null);
  const [filters] = useQueryFilters({});

  const fetchExams = async () => {
    const query = API("exams");

    const data = await query
      .where("student_id", "=", id)
      .with("student{name},teacher{name},subject{name},lessons{lesson{name}}")
      .sort("-created_at")
      .fetch(filters);
    setExams(data);
  };

  const copyLink = (exam) => {
    const link = getExamShareLink(exam);
    copy(link);
    toast("Link panoya kopyalandı");
  };

  useEffect(() => {
    fetchExams();
    // eslint-disable-next-line
  }, []);

  if (!exams) return <Loader />;

  return (
    <>
      <TableContainer>
        <Head>
          <Title style={{ width: "1%" }}></Title>
          <Title>Sınav Türü</Title>
          <Title>Öğretmen</Title>
          <Title>Konu</Title>
          <Title>Tarih</Title>
          <Title style={{ textAlign: "right" }}>Toplam Net</Title>
          <Title style={{ textAlign: "right" }}>Başarı %</Title>
          <Title>Link</Title>
          <Title>Sonuç</Title>
        </Head>
        <Body>
          {exams.data.map((exam, index) => (
            <Row key={index}>
              <Cell style={{ fontWeight: "bold" }}>{index + 1})</Cell>
              <Cell>
                <InnerLink to={`/exams/${exam.id}`}>
                  {EXAM_TYPE_LABELS[exam.exam_type]}
                </InnerLink>
              </Cell>
              <Cell>{exam.teacher?.name}</Cell>
              <Cell>{exam.subject?.name}</Cell>
              <Cell>{dayjs(exam.created_at).format("DD-MM-YYYY")}</Cell>
              <Cell style={{ textAlign: "right" }}>
                {exam.total_net_value ? exam.total_net_value.toFixed(2) : "?"}
              </Cell>
              <Cell style={{ textAlign: "right" }}>
                {exam.total_success_rate
                  ? exam.total_success_rate.toFixed(2)
                  : "?"}
              </Cell>
              <Cell>
                <LinkButton onClick={() => copyLink(exam)}>
                  Linki Kopyala
                </LinkButton>
              </Cell>
              <Cell>
                <ExternalLink href={`${DOMAIN}/r/e/${exam.nano_id}`}>
                  Rapor
                </ExternalLink>
              </Cell>
            </Row>
          ))}
        </Body>
      </TableContainer>
    </>
  );
}
