import React from "react";
import styled from "styled-components";

const LinkSection = styled.li`
  margin: 0px;
  padding: 0px;
  width: auto;
  flex-grow: 4;
  text-align: center;
  background-color: ${(props) => (props.isActive ? "#e0e6ff" : "unset")};
`;

const Link = styled.a`
  width: 100%;
  height: 100%;
  display: block;
  transition: background-color 100ms linear;
  cursor: pointer;
  padding: 4px 0px;

  &:hover {
    background: #f1f1f1;
  }
`;

const LinkImage = styled.img`
  height: 36px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

export default function PageLink({
  onClick,
  icon,
  children,
  isActive = false,
}) {
  return (
    <LinkSection isActive={isActive}>
      <Link onClick={onClick}>
        <LinkImage src={icon} />
        <Title>{children}</Title>
      </Link>
    </LinkSection>
  );
}
