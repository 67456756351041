import React, { useState } from "react";
import Form from "../../../../../components/Form";
import TextInput from "../../../../../components/Form/TextInput";
import PrimaryButton from "../../../../../components/Form/PrimaryButton";
import LessonSelect from "../../../../../components/Form/LessonSelect";
import TeacherSelect from "../../../../../components/Form/TeacherSelect";
import insert from "../../../../../axe-api/insert";
import update from "../../../../../axe-api/update";

const RULES = {
  lesson_id: "required|numeric",
  teacher_id: "required|numeric",
  hour_per_month: "required|numeric|min:1|max:250",
};

export default function LessonForm({ id, studentLesson = null, onClose }) {
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [form, setForm] = useState({
    lesson_id: studentLesson?.lesson_id || null,
    teacher_id: studentLesson?.teacher_id || null,
    hour_per_month: studentLesson?.hour_per_month || 0,
  });

  const handleSubmit = async () => {
    setLoading(true);
    if (studentLesson) {
      await update(`students/${id}/lessons/${studentLesson.id}`, form);
    } else {
      await insert(`students/${id}/lessons`, form);
    }
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        rules={RULES}
        state={form}
        setState={setForm}
        setValid={setValid}
      >
        <LessonSelect
          name="lesson_id"
          title="Ders"
          defaultValue={studentLesson?.lesson}
        />
        <TeacherSelect
          name="teacher_id"
          title="Öğretmen"
          defaultValue={studentLesson?.teacher}
        />
        <TextInput
          type="number"
          name="hour_per_month"
          title="Ders Saati (Aylık)"
          placeholder="10"
          defaultValue={form.hour_per_month}
        />

        <PrimaryButton
          type="submit"
          isLoading={isLoading}
          disabled={isValid === false}
        >
          Kaydet
        </PrimaryButton>
      </Form>
    </>
  );
}
