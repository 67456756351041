import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
`;

const ResultType = styled.div``;

const NetValue = styled.div``;

export default function ResultLine({ title, value, color }) {
  return (
    <Container style={{ color }}>
      <ResultType>{title}</ResultType>
      <NetValue>{value}</NetValue>
    </Container>
  );
}
