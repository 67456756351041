import { useCallback } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import DashboardTemplate, {
  PageTitle,
} from "../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../components/Form/PrimaryButton";
import Drawer from "../../components/Drawer";
import { useEffect, useState } from "react";
import NewStudentForm from "./NewStudentForm";
import API from "../../axe-api/api";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { useQueryFilters } from "../../hooks";
import PhoneDisplay from "../../components/Displays/PhoneDisplay";
import StudentLink from "../../components/StudentLink";
import IsActive from "../../components/Displays/IsActive";
import SearchInput from "../../components/Form/SearchInput";
import Loader from "../../components/Loader";
import Number from "../../components/Number";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function DashboardPage() {
  const [drawer, setDrawer] = useState(null);
  const [students, setStudents] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });

  const handleAddButton = () => {
    setDrawer({
      title: "Öğrenci Ekle",
      component: (
        <NewStudentForm
          onClose={() => setDrawer(null)}
          refetch={fetchStudents}
        />
      ),
    });
  };

  const fetchStudents = async () => {
    const query = API("students");

    if (filters.searchText) {
      query
        .whereLike("name", filters.searchText)
        .orWhereLike("phone", filters.searchText)
        .orWhereLike("branch", filters.searchText)
        .orWhereLike("parent_name", filters.searchText)
        .orWhereLike("parent_phone", filters.searchText)
        .orWhereLike("school.name", filters.searchText)
        .orWhere("id", filters.searchText.replace(/\*/g, ""));
    }

    const data = await query.with("school{name}").fetch(filters);
    setStudents(data);
  };

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  if (!students) return <Loader />;

  return (
    <>
      <Drawer data={drawer} onClose={setDrawer} />
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Öğrenciler</PageTitle>
          <SearchInput onChange={debouncedSearchChange} />
          <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title width="1%">Durum</Title>
            <Title width="1%">No</Title>
            <Title>Ad Soyad</Title>
            <Title>Öğrenci Telefon</Title>
            <Title>Okul</Title>
            <Title>Veli</Title>
            <Title>Veli Telefon</Title>
          </Head>
          <Body>
            {students.data.map((student, index) => (
              <Row key={student.id}>
                <Cell>
                  <IsActive value={student.is_active} />
                </Cell>
                <Cell>
                  <Number>{student.id}</Number>
                </Cell>
                <Cell>
                  <StudentLink student={student}>{student.name}</StudentLink>
                </Cell>
                <Cell>
                  <StudentLink student={student}>
                    <PhoneDisplay>{student.phone}</PhoneDisplay>
                  </StudentLink>
                </Cell>
                <Cell>{student?.school?.name}</Cell>
                <Cell>{student.parent_name}</Cell>
                <Cell>
                  <PhoneDisplay>{student.parent_phone}</PhoneDisplay>
                </Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
        <Pagination
          data={students.pagination}
          filters={filters}
          setFilters={setFilters}
        />
      </DashboardTemplate>
    </>
  );
}
