import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import Loader from "../../../components/Loader";
import Template from "../Template";
import { PageNav, PageLink, ItemLink } from "../Shared";
import { EXAM_TYPE_LABELS } from "../../../constants";

const getSubjectTitle = (exam, subjects) => {
  const found = subjects.find((lesson) => lesson.id === exam.subject_id);
  return found?.name;
};

export default function ExamListPage() {
  const history = useHistory();
  const { nanoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getResults = async () => {
      const { data } = await axios.get(`results/student/${nanoId}/exams`);
      setData(data);
    };

    getResults();
  }, [nanoId]);

  const handleClick = ({ nano_id }) => {
    history.push(`/r/e/${nano_id}`);
  };

  if (!data) return <Loader />;

  const { student, studentExams, subjects } = data;

  return (
    <Template student={student}>
      <PageNav>
        <PageLink>Sınavlar</PageLink>
      </PageNav>
      {studentExams.map((exam, index) => (
        <ItemLink key={index} onClick={() => handleClick(exam)}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {EXAM_TYPE_LABELS[exam.exam_type]}{" "}
              {exam.subject_id && (
                <span>- {getSubjectTitle(exam, subjects)}</span>
              )}
            </div>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              %{exam.total_success_rate}
            </div>
          </div>
          <small>{dayjs(exam.created_at).format("DD/MM/YYYY")}</small>
        </ItemLink>
      ))}
    </Template>
  );
}
