import InnerLink from "../InnerLink";

export default function StudentLink({
  student: { id, name },
  children,
  ...props
}) {
  return (
    <InnerLink to={`/students/${id}`} {...props}>
      {children || name}
    </InnerLink>
  );
}
