import { useState } from "react";
import TextInput from "../../../components/Form/TextInput";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import insert from "../../../axe-api/insert";
import update from "../../../axe-api/update";
import Form from "../../../components/Form";

const RULES = {
  name: "required",
  phone: "min:10|max:10|regex:/^\\d+/",
  email: "email",
};

export default function NewTeacherForm({ teacher = null, onClose, refetch }) {
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: teacher?.name || "",
    phone: teacher?.phone || "",
    email: teacher?.email || "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    if (teacher) {
      await update(`teachers/${teacher.id}`, form);
    } else {
      await insert(`teachers`, form);
    }
    setLoading(false);
    refetch();
    onClose();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      rules={RULES}
      state={form}
      setState={setForm}
      setValid={setValid}
    >
      <TextInput
        name="name"
        title="Ad Soyad"
        placeholder="Ad soyad"
        defaultValue={form.name}
      />
      <TextInput
        name="phone"
        title="Telefon"
        placeholder="5324442211"
        maxLength="10"
        defaultValue={form.phone}
      />
      <TextInput
        name="email"
        title="E-posta"
        placeholder="ahmet@mail.com"
        defaultValue={form.email}
      />
      <PrimaryButton
        type="submit"
        isLoading={isLoading}
        disabled={isValid === false}
      >
        Kaydet
      </PrimaryButton>
    </Form>
  );
}
