import styled from "styled-components";
import LessonSelect from "../../components/Form/LessonSelect";
import patch from "../../axe-api/patch";

const BoxContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
`;

const BoxTitle = styled.div`
  font-weight: bold;
  border: 1px solid #f77979;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: -20px -20px 20px -20px;
  padding: 10px 20px;
`;

const OPTIONS = ["A", "B", "C", "D", "E"];

const OptionRow = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 10px;
`;

const OptionCircle = styled.div`
  background-color: ${({ isMarked }) => (isMarked ? "#f77979" : "white")};
  border: 1px solid #ffdede;
  color: ${({ isMarked }) => (isMarked ? "white" : "#999")};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const OptionCount = styled(OptionCircle)`
  background-color: #999;
  border: 1px solid #888;
  color: white;
`;

export default function AnswerBox({
  index,
  exam,
  items,
  refetch,
  isEditable = true,
}) {
  const currentValue = exam[`box${index}`];

  const handleChange = async ({ value }) => {
    await patch(`group-exams/${exam.id}`, {
      [`box_${index}`]: value,
    });
    await refetch();
  };

  return (
    <BoxContainer>
      <BoxTitle>
        {isEditable && (
          <LessonSelect
            name="lesson_id"
            title="Ders"
            isCreatable={true}
            onChange={handleChange}
            defaultValue={currentValue}
          />
        )}
        {!isEditable && (currentValue?.name || "?")}
      </BoxTitle>
      {items.map((value, index) => (
        <OptionRow key={index}>
          <OptionCount>{index + 1}</OptionCount>
          {OPTIONS.map((option) => (
            <OptionCircle key={option} isMarked={value === option}>
              {option}
            </OptionCircle>
          ))}
        </OptionRow>
      ))}
    </BoxContainer>
  );
}
