import styled from "styled-components";

export const InputContainer = styled.div`
  margin-bottom: 16px;
`;

export const InputLabel = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
`;

export const InputError = styled.p`
  padding: 0px;
  margin: 5px 0px 0px 0px;
  color: #d7087a;
`;
