import React from "react";
import DrawerBox from "./DrawerBox";

export default function Drawer({ data, onClose }) {
  return (
    <>
      {data && (
        <DrawerBox title={data.title} onClose={() => onClose(null)}>
          {data.component}
        </DrawerBox>
      )}
    </>
  );
}
