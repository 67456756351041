import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader";
import Template from "../Template";
import SuccessChart from "./SuccessChart";
import { groupBy } from "underscore";
import { EXAM_TYPE_LABELS } from "../../../constants";

const getGroupedLessonData = ({ examLessons, lessons }) => {
  const rows = groupBy(examLessons, (i) => i.lesson_id);
  return Object.keys(rows)
    .map((lesson_id) => {
      return {
        lesson: lessons.find((i) => i.id === parseInt(lesson_id, 10)),
        exams: rows[lesson_id].filter((i) => i.success_rate >= 0),
      };
    })
    .filter((i) => i.exams.length > 0);
};

const getExamTypeData = ({ studentExams }) => {
  const rows = groupBy(studentExams, (i) => i.exam_type);
  return Object.keys(rows)
    .map((exam_type) => {
      return {
        exam_type,
        exams: rows[exam_type].filter((i) => i.total_success_rate >= 0),
      };
    })
    .filter((i) => i.exams.length > 0);
};

export default function StudentResultPage() {
  const { nanoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getResults = async () => {
      const { data } = await axios.get(`results/student/${nanoId}`);
      setData(data);
    };

    getResults();
  }, [nanoId]);

  if (!data) return <Loader />;

  const { student, examLessons, studentExams, lessons } = data;
  const lessonBasedStats = getGroupedLessonData({ examLessons, lessons });
  const examTypeBasedStats = getExamTypeData({ studentExams });

  return (
    <Template student={student}>
      {student.order_index ? (
        <h2>Genel Başarı Sırası: {student.order_index}</h2>
      ) : (
        ""
      )}
      <SuccessChart
        title="Tüm sınavlar"
        data={studentExams.map((i) => i.total_success_rate)}
      />
      {examTypeBasedStats.map((item, index) => (
        <SuccessChart
          key={index}
          title={`${EXAM_TYPE_LABELS[item.exam_type]} Sonuçları`}
          data={item.exams.map((i) => i.total_success_rate)}
        />
      ))}
      {lessonBasedStats.map((item, index) => (
        <SuccessChart
          key={index}
          title={`${item.lesson.name} Sonuçları`}
          data={item.exams.map((i) => i.success_rate)}
        />
      ))}
    </Template>
  );
}
