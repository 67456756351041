import React, { useState } from "react";
import SelectInput from "../SelectInput";
import { EXAM_TYPE_LIST } from "../../../constants";

export default function ExamTypeInput({
  onChange,
  isRequired = false,
  ...props
}) {
  const [examType, setExamType] = useState(null);

  const handleChange = ({ name, value }) => {
    setExamType(value);
    onChange({ name, value });
  };

  let selectedExam = null;
  if (examType) {
    selectedExam = EXAM_TYPE_LIST.find(
      (i) =>
        i.value === examType ||
        i.options.find((sub) => i.value !== null && sub.value === examType)
    );
  }

  return (
    <div>
      <SelectInput
        isRequired={isRequired}
        {...props}
        options={EXAM_TYPE_LIST}
        value={selectedExam}
        onChange={handleChange}
      />
    </div>
  );
}
