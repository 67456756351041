import { useCallback } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { debounce } from "lodash";
import DashboardTemplate, {
  PageTitle,
} from "../../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import Drawer from "../../../components/Drawer";
import { useEffect, useState } from "react";
import NewExamForm from "./NewExamForm";
import API from "../../../axe-api/api";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { useQueryFilters } from "../../../hooks";
import SearchInput from "../../../components/Form/SearchInput";
import StudentLink from "../../../components/StudentLink";
import { EXAM_TYPE_LABELS } from "../../../constants";
import InnerLink from "../../../components/InnerLink";
import Loader from "../../../components/Loader";
import { getExamShareLink, getReportPageDomain } from "../../../helpers/shared";
import ExternalLink from "../../../components/ExternalLink";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LinkButton = styled(PrimaryButton)`
  padding: 4px 10px;
  height: 30px;
  font-size: 14px;
`;

const DOMAIN = getReportPageDomain();

export default function ExamsListPage() {
  const [drawer, setDrawer] = useState(null);
  const [exams, setExams] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });

  const handleAddButton = () => {
    setDrawer({
      title: "Sınav Ekle",
      component: <NewExamForm onClose={() => setDrawer(null)} />,
    });
  };

  const fetchExams = async () => {
    const query = API("exams");

    if (filters.searchText) {
      query
        .whereLike("student.name", filters.searchText)
        .orWhereLike("teacher.name", filters.searchText)
        .orWhereLike("subject.name", filters.searchText);
    }

    const data = await query
      .with("student{name},teacher{name},subject{name},lessons{lesson{name}}")
      .sort("-created_at")
      .fetch(filters);
    setExams(data);
  };

  useEffect(() => {
    fetchExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  const copyLink = (exam) => {
    const link = getExamShareLink(exam);
    copy(link);
    toast("Link panoya kopyalandı");
  };

  if (!exams) return <Loader />;

  return (
    <>
      <Drawer data={drawer} onClose={setDrawer} />
      <DashboardTemplate>
        <ActionBox>
          <PageTitle>Sınavlar</PageTitle>
          <SearchInput onChange={debouncedSearchChange} />
          <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
        </ActionBox>
        <TableContainer>
          <Head>
            <Title style={{ width: "1%" }}></Title>
            <Title>Sınav Türü</Title>
            <Title>Öğrenci</Title>
            <Title>Öğretmen</Title>
            <Title>Konu</Title>
            <Title>Tarih</Title>
            <Title style={{ textAlign: "right" }}>Toplam Net</Title>
            <Title style={{ textAlign: "right" }}>Başarı %</Title>
            <Title>Link</Title>
            <Title>Sonuç</Title>
          </Head>
          <Body>
            {exams.data.map((exam, index) => (
              <Row key={index}>
                <Cell style={{ fontWeight: "bold" }}>{index + 1})</Cell>
                <Cell>
                  <InnerLink to={`/exams/${exam.id}`}>
                    {EXAM_TYPE_LABELS[exam.exam_type]}
                  </InnerLink>
                </Cell>
                <Cell>
                  <StudentLink student={exam.student} />
                </Cell>
                <Cell>{exam.teacher?.name}</Cell>
                <Cell>{exam.subject?.name}</Cell>
                <Cell>{dayjs(exam.created_at).format("DD-MM-YYYY")}</Cell>
                <Cell style={{ textAlign: "right" }}>
                  {exam.total_net_value ? exam.total_net_value.toFixed(2) : "?"}
                </Cell>
                <Cell style={{ textAlign: "right" }}>
                  {exam.total_success_rate
                    ? exam.total_success_rate.toFixed(2)
                    : "?"}
                </Cell>
                <Cell>
                  <LinkButton onClick={() => copyLink(exam)}>
                    Linki Kopyala
                  </LinkButton>
                </Cell>
                <Cell>
                  <ExternalLink href={`${DOMAIN}/r/e/${exam.nano_id}`}>
                    Rapor
                  </ExternalLink>
                </Cell>
              </Row>
            ))}
          </Body>
        </TableContainer>
        <Pagination
          data={exams.pagination}
          filters={filters}
          setFilters={setFilters}
        />
      </DashboardTemplate>
    </>
  );
}
