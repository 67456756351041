import { ADD_QUESTION, REMOVE_QUESTION } from "../types";
const DEFAULT_STATE = {
  title: null,
  question: null,
  callback: null,
};

const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ADD_QUESTION:
      return {
        title: action.payload.title,
        question: action.payload.question,
        callback: action.payload.callback,
      };
    case REMOVE_QUESTION:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
