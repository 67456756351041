import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
  color: #d63031;
`;

export default function RequiredMark() {
  return <StyledSpan>*</StyledSpan>;
}
