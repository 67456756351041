import { useState } from "react";
import { useHistory } from "react-router-dom";
import TextInput from "../../components/Form/TextInput";
import PrimaryButton from "../../components/Form/PrimaryButton";
import insert from "../../axe-api/insert";
import Form from "../../components/Form";

const RULES = {
  name: "required",
  phone: "required|min:10|max:10|regex:/^\\d+/",
};

export default function NewStudentForm({ onClose }) {
  const history = useHistory();
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    phone: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    const { id } = await insert("students", form);
    setLoading(false);
    onClose();
    history.push(`/students/${id}`);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      rules={RULES}
      state={form}
      setState={setForm}
      setValid={setValid}
    >
      <TextInput name="name" title="Ad Soyad" placeholder="Ad soyad" />
      <TextInput
        name="phone"
        title="Öğrenci Telefon"
        placeholder="5324442211"
        maxLength="10"
      />
      <PrimaryButton
        type="submit"
        isLoading={isLoading}
        disabled={isValid === false}
      >
        Kaydet
      </PrimaryButton>
    </Form>
  );
}
