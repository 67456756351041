import styled from "styled-components";

const StyledPage = styled.button`
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #444;
  cursor: pointer;
  padding: 12px;
  white-space: nowrap;

  & svg {
    margin-right: 7px;
    margin-left: 7px;
  }

  &:hover {
    color: #6a6acc;
  }

  &:disabled {
    color: #ddd;
    cursor: unset;
  }

  &.is-active {
    background: #6a6acc;
    border-radius: 2px;
    color: #ffffff;
  }
`;

export default function Page({
  active = false,
  children,
  onClick,
  disabled = false,
  ...props
}) {
  return (
    <StyledPage
      type="button"
      className={active ? "is-active" : ""}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledPage>
  );
}
