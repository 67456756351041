import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: #333;
  text-decoration: none;

  &:hover {
    color: #6a6acc;
  }
`;

export default function InnerLink({ children, ...props }) {
  return <StyledLink {...props}>{children}</StyledLink>;
}
