import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader";
import Template from "../Template";
import { PageNav, PageLink, ItemLink } from "../Shared";

const getLessonTitle = (studentLesson, lessons) => {
  const found = lessons.find((lesson) => lesson.id === studentLesson.lesson_id);
  return found?.name || "Bilinmeyen Ders";
};

export default function LessonListPage() {
  const history = useHistory();
  const { nanoId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getResults = async () => {
      const { data } = await axios.get(`results/student/${nanoId}/lessons`);
      setData(data);
    };

    getResults();
  }, [nanoId]);

  const handleClick = (studentLesson) => {
    history.push(`/r/u/${data.student.nano_id}/lessons/${studentLesson.id}`);
  };

  if (!data) return <Loader />;

  const { student, studentLessons, lessons } = data;

  return (
    <Template student={student}>
      <PageNav>
        <PageLink>Dersler</PageLink>
      </PageNav>
      {studentLessons.map((studentLesson, index) => (
        <ItemLink key={index} onClick={() => handleClick(studentLesson)}>
          {getLessonTitle(studentLesson, lessons)}
          <small>{studentLesson.hour_per_month} saat/ay</small>
        </ItemLink>
      ))}
    </Template>
  );
}
