import styled from "styled-components";
import { useParams } from "react-router";
import TextInput from "../../../../components/Form/TextInput";
import AutoSave from "../../../../components/Form/AutoSave";
import SchoolSelect, {
  toSelectItem,
} from "../../../../components/Form/SchoolSelect";
import SelectInput from "../../../../components/Form/SelectInput";
import { useEffect, useState } from "react";
import useQuery from "../../../../axe-api/userQuery";
import {
  GRADUATION_TITLES,
  CLASS_YEARS,
  STUDENT_ACTIVATION_TYPES,
} from "../../../../constants";
import Loader from "../../../../components/Loader";
import { toFilledNumber } from "../../../../helpers/shared";

const Container = styled.div`
  max-width: 600px;
  padding-top: 20px;
`;

export default function InfoTab() {
  const { query } = useQuery("students");
  const { id } = useParams();
  const [student, setStudent] = useState(null);

  useEffect(() => {
    const getDetail = async () => {
      const data = await query.with("school{id|name}").find(id);
      setStudent(data);
    };
    getDetail();
  }, [id, query]);

  if (student === null) return <Loader />;

  const resouce = `students/${id}`;

  return (
    <Container>
      <AutoSave resource={resouce}>
        <TextInput
          name="id"
          title="No"
          rules="required"
          defaultValue={toFilledNumber(student.id)}
          disabled
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <SelectInput
          name="is_active"
          title="Aktiflik Durumu"
          options={STUDENT_ACTIVATION_TYPES}
          defaultValue={STUDENT_ACTIVATION_TYPES.find(
            (i) => i.value === student.is_active
          )}
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <TextInput
          name="name"
          title="Ad Soyad"
          rules="required"
          placeholder="Ad soyad"
          defaultValue={student.name}
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <TextInput
          type="tel"
          name="phone"
          title="Telefon"
          rules="required|min:10|max:10|regex:/^[0-9]+$/"
          defaultValue={student.phone}
          placeholder="5324442211"
          maxLength="10"
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <TextInput
          name="parent_name"
          title="Veli Ad Soyad"
          rules="required"
          placeholder="Ad soyad"
          defaultValue={student.parent_name}
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <TextInput
          type="tel"
          name="parent_phone"
          title="Veli Telefon"
          rules="required|min:10|max:10|regex:/^[0-9]+$/"
          defaultValue={student.parent_phone}
          placeholder="5324442211"
          maxLength="10"
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <SchoolSelect
          name="school_id"
          title="Okul"
          defaultValue={student.school && toSelectItem(student.school)}
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <SelectInput
          name="graduation"
          title="Mezuniyet"
          options={GRADUATION_TITLES}
          defaultValue={
            student.graduation &&
            GRADUATION_TITLES.find((i) => i.value === student.graduation)
          }
        />
      </AutoSave>
      <AutoSave resource={resouce}>
        <SelectInput
          name="class_year"
          title="Sınıf"
          options={CLASS_YEARS}
          isClearable
          defaultValue={
            student.class_year &&
            CLASS_YEARS.find((i) => i.value === student.class_year)
          }
        />
      </AutoSave>
    </Container>
  );
}
