import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import LessonBox from "./LessonBox";
import Loader from "../../../components/Loader";
import Template from "../Template";
import { EXAM_TYPE_LABELS } from "../../../constants";
import { PageNav, PageLink, EmptyBox } from "../Shared";

export default function ExamResult() {
  const { nanoId } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getResults = async () => {
      const { data } = await axios.get(`results/exam/${nanoId}`);
      setData(data);
    };

    getResults();
  }, [nanoId]);

  if (!data) return <Loader />;

  const filteredExams = data.examLessons.filter(
    (item) => item.question_count > 0
  );

  return (
    <Template student={data.student}>
      <PageNav>
        <PageLink
          onClick={() => history.push(`/r/u/${data.student.nano_id}/exams`)}
        >
          Sınavlar
        </PageLink>
        <PageLink>
          {EXAM_TYPE_LABELS[data.exam_type]}
          {data.subject && <span> - {data.subject.name}</span>}
        </PageLink>
      </PageNav>

      <EmptyBox array={filteredExams} />

      {filteredExams.map((examLesson, index) => (
        <LessonBox key={index} examLesson={examLesson} lessons={data.lessons} />
      ))}
    </Template>
  );
}
