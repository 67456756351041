import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { debounce } from "lodash";
import PrimaryButton from "../../../../components/Form/PrimaryButton";
import Drawer from "../../../../components/Drawer";
import LessonForm from "./Drawers/LessonForm";
import { useQueryFilters } from "../../../../hooks";
import API from "../../../../axe-api/api";
import {
  TableContainer,
  Head,
  Title,
  Body,
  Row,
  Cell,
} from "../../../../components/Table";
import Pagination from "../../../../components/Table/Pagination";
import PhoneDisplay from "../../../../components/Displays/PhoneDisplay";
import SearchInput from "../../../../components/Form/SearchInput";
import LessonLink from "../../../../components/Links/LessonLink";
import TeacherLink from "../../../../components/Links/TeacherLink";
import Dropdown, { DropdownItem } from "../../../../components/Form/Dropdown";
import { useDispatch } from "react-redux";
import { addQuestion } from "../../../../store/actions/question";
import deleteRequest from "../../../../axe-api/deleteRequest";
import Loader from "../../../../components/Loader";

const ActionBox = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default function LessonTab() {
  const { id } = useParams();
  const [drawer, setDrawer] = useState(null);
  const [studentLessons, setStudentLessons] = useState(null);
  const [filters, setFilters] = useQueryFilters({
    searchText: "",
  });
  const dispatch = useDispatch();

  const fetchStudentLessons = async () => {
    const query = API(`students/${id}/lessons`);

    if (filters.searchText) {
      query
        .whereLike("hour_per_month", filters.searchText)
        .orWhereLike("lesson.name", filters.searchText)
        .orWhereLike("teacher.name", filters.searchText);
    }

    const data = await query
      .with("lesson{name},teacher{name|phone|email}")
      .fetch(filters);
    setStudentLessons(data);
  };

  const handleDrawerClose = () => {
    setDrawer(null);
    fetchStudentLessons();
  };

  const handleAddButton = () => {
    setDrawer({
      title: "Ders Ekle",
      component: <LessonForm id={id} onClose={handleDrawerClose} />,
    });
  };

  const handleEdit = (studentLesson) => {
    setDrawer({
      title: "Ders Güncelle",
      component: (
        <LessonForm
          id={id}
          studentLesson={studentLesson}
          onClose={handleDrawerClose}
        />
      ),
    });
  };

  const deleteQuestion = async (studentLesson) => {
    await deleteRequest(`students/${id}/lessons/${studentLesson.id}`);
    fetchStudentLessons();
  };

  const handleDelete = (studentLesson) => {
    dispatch(
      addQuestion(
        "Emin misin?",
        "Bu kaydı silmek istediğinden emin misin?",
        () => deleteQuestion(studentLesson)
      )
    );
  };

  useEffect(() => {
    fetchStudentLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(async ({ value }) => {
      setFilters({
        ...filters,
        searchText: value ? `*${value}*` : "",
      });
    }, 300),
    []
  );

  if (!studentLessons) return <Loader />;

  return (
    <div>
      <Drawer data={drawer} onClose={handleDrawerClose} />

      <ActionBox>
        <SearchInput onChange={debouncedSearchChange} />
        <PrimaryButton onClick={handleAddButton}>Ekle</PrimaryButton>
      </ActionBox>

      <TableContainer>
        <Head>
          <Title style={{ width: "1%" }}></Title>
          <Title>Ders</Title>
          <Title>Öğretmen</Title>
          <Title>Öğretmen Telefon</Title>
          <Title style={{ textAlign: "right" }}>Ders Saati (Aylık)</Title>
        </Head>
        <Body>
          {studentLessons.data.map((studentLesson, index) => (
            <Row key={index}>
              <Cell>
                <Dropdown>
                  <DropdownItem onClick={() => handleEdit(studentLesson)}>
                    Düzenle
                  </DropdownItem>
                  <DropdownItem onClick={() => handleDelete(studentLesson)}>
                    Sil
                  </DropdownItem>
                </Dropdown>
              </Cell>
              <Cell>
                <LessonLink data={studentLesson.lesson} />
              </Cell>
              <Cell>
                <TeacherLink data={studentLesson.teacher} />
              </Cell>
              <Cell>
                <PhoneDisplay>{studentLesson.teacher.phone}</PhoneDisplay>
              </Cell>
              <Cell style={{ textAlign: "right" }}>
                {studentLesson.hour_per_month}
              </Cell>
            </Row>
          ))}
        </Body>
      </TableContainer>
      <Pagination
        data={studentLessons.pagination}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
