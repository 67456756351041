import { useState } from "react";
import styled from "styled-components";
import { getValidation } from "../../../helpers/forms";
import { InputContainer, InputLabel, InputError } from "../InputStyles";

const StyledInput = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 14px;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  color: #444;
  background-color: white;

  &.is-not-valid {
    border: 1px solid #d7087a;
  }

  &:focus {
    outline: none;
    background-color: #fefefe;
  }

  &::placeholder {
    color: #aaa;
  }

  &:disabled {
    background-color: #f6f6f6;
    color: #666;
  }
`;

export default function TextInput({
  name,
  type = "text",
  title,
  rules = null,
  containerStyle = {},
  onChange,
  ...props
}) {
  const [isValid, setValid] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { value } = event.target;
    if (rules && name && (rules || rules[name])) {
      const { isPassed, errors } = getValidation(
        value,
        title,
        rules[name] || rules
      );
      setValid(isPassed);
      setError(errors);
      if (isPassed) {
        onChange({ name, value });
      }
    } else {
      onChange({ name, value });
    }
  };

  return (
    <InputContainer style={containerStyle}>
      {title && <InputLabel>{title}</InputLabel>}
      <StyledInput
        className={isValid === false && "is-not-valid"}
        type={type}
        onChange={handleChange}
        {...props}
      />
      {error && <InputError>{error}</InputError>}
    </InputContainer>
  );
}
